import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import translationEN from '../resouces/translations/en.json';
import translationDE from '../resouces/translations/de.json';
import { getLang } from './utils';


const resources = {
    en: {
        translation: translationEN
    },
    de: {
        translation: translationDE
    }
};

//@ts-ignore
i18n.use(reactI18nextModule)
    .init({
        resources,
        lng: getLang(),

        keySeparator: false,

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;