import React from 'react';
import './Input.scss';

export interface InputProps {
    style?: 'strong' | 'outline' | 'transparent'
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    type?: 'text' | 'password' | 'email' | 'number' | 'tel' | 'select' | 'custom' | 'textarea'
    label?: string
    labelPosition?: 'horizontal' | 'vertical'
    fullWidth?: boolean
    name?: string
    value?: any
    placeholder?: string
    iconLeft?: string
    iconRight?: string
    className?: string
    disabled?: boolean
    active?: boolean
    onFocus?: any;
    onBlur?: any;
    onChange?: any
    children?: any
    min?: number
    max?: number
    error?: string
    description?: string
};

const Input = (props: InputProps) => {
    const { error, style, size, type, label, description, value, placeholder, className, disabled, onFocus, onBlur, onChange, children, name, min, max, fullWidth } = props;

    let classNames = className + ' form-control';
    if (style) classNames += ' form-control-' + style;
    if (size) classNames += ' form-control-' + size;
    const colSize = fullWidth ? 'col-sm-12' : 'col-sm-6'

    let inputValue = value
    if (typeof inputValue === 'undefined')
        inputValue = ''

    return (
        <div className={`form-group ${colSize}`}>
            <label htmlFor={name}>{label}</label>
            {description && <small>{description}</small>}
            {(type !== 'select' && type !== 'textarea' && type !== 'custom') && <input className={classNames} value={inputValue} onFocus={onFocus} onBlur={onBlur} name={name} onChange={onChange} type={type} placeholder={placeholder} disabled={disabled} min={min} max={max} />}
            {type === 'select' &&
                <>
                    <select className={classNames} value={value} onFocus={onFocus} name={name} onBlur={onBlur} onChange={onChange}>{children}</select>
                    <i className="far fa-angle-down ic-right p0" />
                </>
            }
            {type === 'custom' && <div>{children}</div>}
            {type === 'textarea' && <div><textarea className={classNames} value={value} onFocus={onFocus} onBlur={onBlur} name={name} onChange={onChange} placeholder={placeholder} disabled={disabled} /></div>}
            {error && <div className="alert alert-danger" role="alert">{error}</div>}
        </div>
    )
}

export default Input
