import { Booking } from '../components/types/booking'
import { Price, Rule } from '../components/types/meetingRooms'
import { Details, itemTypes } from './requiredItems'
import { ItemType } from './utils'

export function getPricesForDysplay(booking, currentDaySelection, currentDate) {
    const totalPrice = calculatePrice(booking, undefined, currentDaySelection, currentDate)
    const guestNumber = booking?.timings.reduce((attendeesNoTotal, item) => attendeesNoTotal + item.attendeesNo, 0) || 1

    return {
        totalPrice: totalPrice.price,
        pricePerGuest: totalPrice.price / (Math.floor(guestNumber / booking.timings.length || 1)),
        totalPriceIncVAT: totalPrice.grossPrice,
        pricePerGuestIncVAT: totalPrice.grossPrice / (Math.floor(guestNumber / booking.timings.length || 1))
    }
}

const logPrices = false;
function log(...args) {
    if (logPrices) {
        console.log(...args);
    }  
}

function countItemRepeat(booking, currentDate, currentDaySelection, selectedItem: ItemType, type) {
    let count = 0
    if (!booking)
        return 0
    for (let timing of booking.timings) {
        if (timing.date !== currentDate || !currentDaySelection) {
            if (timing.details)
                for (let item of timing.details[type]) {
                    if (item.id === selectedItem.id)
                        count++
                }
        } else {
            for (let item of currentDaySelection[type]) {
                if (item.id === selectedItem.id)
                    count++
            }
        }
    }
    log('Count item', selectedItem.id, count)
    return count
}

export const calculatePrice = (booking: Booking | undefined, sectionType: string | undefined = undefined, currentDaySelection?: Details, currentDate?: string, singleItemPrice = false, singleItemCount?: number) => {

    function validateRules(priceItem: Price, selectedItem: ItemType, type: string) {
        if (!priceItem.rules)
            return true
        let valid = true
        for (let rule of priceItem.rules as Rule[]) {

            if (rule.type === 'selectedDays') {
                const { minimumNumber, maximumNumber } = rule?.data
                const itemCount = singleItemCount ? singleItemCount : countItemRepeat(booking, currentDate, currentDaySelection, selectedItem, type)
                if (itemCount < minimumNumber || 0) {
                    valid = false
                }
                if (itemCount > (maximumNumber || 6)) {
                    valid = false
                }
            }
            if (rule.type === 'unitRange') {
                const { minimumNumber, maximumNumber } = rule?.data
                if (minimumNumber && (selectedItem.inputValue || 1) < minimumNumber) {
                    valid = false
                }
                if (maximumNumber && (selectedItem.inputValue || 1) > maximumNumber) {
                    valid = false
                }
            }
        }
        if (!valid) {
            log('Skiping price because of rules do not apply')
        }
        return valid
    }

    function calculatePriceAdjustment(adjustmentPriceItem: Price, priceItemValue: number, selectedItem: ItemType, type: string) {
        let adjustmentPrice = 0;
        log('adjustment', adjustmentPriceItem);
        if (adjustmentPriceItem.type === 'fixedPrice' || adjustmentPriceItem.type === 'fixed') {
            adjustmentPrice += adjustmentPriceItem.value;
        }
        else if (adjustmentPriceItem.type === 'percentage') {
            log('adjustmentPriceItem percentage', (priceItemValue * adjustmentPriceItem.value) / 100);
            adjustmentPrice += (priceItemValue * adjustmentPriceItem.value) / 100;
        }

        if (validateRules(adjustmentPriceItem, selectedItem, type)) {
            return adjustmentPrice;
        }

        return 0;
    }

    function addTax(price: Price, priceItemValue: number) {
        const tax = priceItemValue * (price.taxRateValue / 100);
        log('Adding tax', tax);
        return tax;
    }

    function calculatePriceForItem(item: ItemType, type: string, attendies: number, parentInputValue?: number) {       
        let price = 0;
        let grossPrice = 0;
        const { prices, priceAdjustments, paxPerUnit, inputValue } = item;
        log('\n\n ===Item===', item.name, item, item.prices, item.priceAdjustments);

        for (let priceItem of prices as Price[]) {
            log('\n-- PriceItem', priceItem)

            if (!validateRules(priceItem, item, type))
                continue

            let priceItemValue = 0;

            let units = -1
            if (inputValue >= 0) {
                units = inputValue
            } else if (parentInputValue && parentInputValue >= 0) {
                units = parentInputValue
            }

            if (priceItem.type === 'fixed') {
                priceItemValue += priceItem.value
            }
            else if (priceItem.type === 'perUnit') {
                if (units === -1) {
                    units = 1
                }
               
                priceItemValue += units * priceItem.value
            }
            else if (priceItem.type === 'perGuest') {
                if (units === -1) {
                    units = attendies
                }
                
                if (paxPerUnit) {
                    priceItemValue += priceItem.value * units * paxPerUnit
                } else {
                    priceItemValue += priceItem.value * units
                }
            }

            if (priceItem.adjustment) {
                priceItemValue += calculatePriceAdjustment(priceItem.adjustment as Price, priceItemValue, item, type)
            }

            price += priceItemValue;
            grossPrice += priceItemValue + addTax(priceItem, priceItemValue);
            log('priceItemValue', priceItemValue, 'price', price, 'grossPrice', grossPrice)
        }

        log('\n--------Adding adjustments');
        for (let priceAdjustmentItem of priceAdjustments as Price[]) {
            const priceAdjustmentValue = calculatePriceAdjustment(priceAdjustmentItem, price, item, type);
            const grossPriceAdjustmentValue = calculatePriceAdjustment(priceAdjustmentItem, grossPrice, item, type);
            price += priceAdjustmentValue;
            grossPrice += grossPriceAdjustmentValue;
            log('priceAdjustmentValue', priceAdjustmentValue, 'price', price, 'grossPrice', grossPrice);
        }

        const minPrice = prices.find(pr => pr.type === 'minimum');
        if (minPrice && price < minPrice.value) {
            price = minPrice.value;
            grossPrice = price + addTax(minPrice, price);
            log('Setting min price', minPrice.value, 'or price', price, 'grossPrice', grossPrice);
        }

        if (item.couponCodeValue) {
            const priceDiscount = calculatePriceAdjustment(item.couponCodeValue as Price, price, item, type);
            if (price > 0) {
                grossPrice += ((grossPrice * priceDiscount) / price);
            }
            else {
                grossPrice = 0;
            }
            
            price += priceDiscount;
            log('Calculating coupon price discount', priceDiscount, 'price', price, 'grossPrice', grossPrice);
        }

        if (!singleItemPrice) {
            for (let upgrade of item.upgradeItems || []) {
                const itemPrice = calculatePriceForItem(upgrade, type, attendies, item.inputValue);
                price += itemPrice.price;
                grossPrice += itemPrice.grossPrice;
            }
        }   

        if (price <= 0) {
            price = 0;
            grossPrice = 0;
        }

        return {
            price: price,
            grossPrice: grossPrice
        }
    }

    const calculatePriceForDay = (details: Details, attendies: number) => {
        let price = 0;
        let grossPrice = 0;
        for (let type of itemTypes) {
            if (details[type])
                for (let item of details[type]) {
                    if (!sectionType || (sectionType && sectionType === type)) {
                        const itemPrice = calculatePriceForItem(item, type, attendies)
                        price += itemPrice.price;
                        grossPrice += itemPrice.grossPrice;
                    }
                }
        }

        return {
            price: price,
            grossPrice: grossPrice
        }
    }

    let price = 0;
    let grossPrice = 0;
    if (!booking) {
        return { price: 0, grossPrice: 0 };
    }
        
    log('\n\n\n -------------------- Calculate Price --------------------');
    for (let timing of booking.timings) {
        if (timing.date != currentDate || !currentDaySelection) {
            if (timing.details) {
                const itemPrice = calculatePriceForDay(timing.details, timing.attendeesNo);
                price += itemPrice.price;
                grossPrice += itemPrice.grossPrice;
            }  
        } else {
            const itemPrice = calculatePriceForDay(currentDaySelection, timing.attendeesNo);
            price += itemPrice.price;
            grossPrice += itemPrice.grossPrice;
        }
    }

    return {
        price: price,
        grossPrice: grossPrice
    }
}

export function calculatePriceForItem(booking: Booking | undefined, item: ItemType, attendeesNo, type: string) {
    const bookingMock = {
        timings: [
            {
                attendeesNo,
                details: { [type]: [item] }
            }
        ]
    }

    const itemCount = countItemRepeat(booking, undefined, undefined, item, type)
    return calculatePrice(bookingMock as any, type, undefined, undefined, true, itemCount)
}
