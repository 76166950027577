import React from 'react';
import { SeatingLayout } from '../../types/meetingRooms';
import './Filter.scss'

type Props = {
    seetingLayouts: SeatingLayout[] | undefined
    seatingLayout: string | undefined
    all: boolean
    t: any
    setFilterLayout: (layout: string) => void
}

export default function Filter({ seetingLayouts, seatingLayout, all, setFilterLayout, t }: Props) {

    return (
        <div className="row col-sm-12 type-filter">
            <div className="btn-group btn-group-toggle" data-toggle="buttons">
                {all && <label className={`btn btn-secondary ${seatingLayout === 'all' ? 'active' : ''}`}>
                    <input type="radio" name="options" id="option1" onClick={() => setFilterLayout('all')} /> {t('all')}
                </label>}

                {seetingLayouts?.map((item, index) => {
                    const icon = require('./../../../resouces/icons/seating_formats/' + item.iconPath)
                    return <label className={`btn btn-secondary ${seatingLayout === item.layout ? 'active' : ''}`} key={index}>
                        <img src={icon} alt="React Logo" />
                        <input type="radio" name="options" id="option2" onClick={() => setFilterLayout(item.layout)} /> {item.name}
                    </label>
                })}
            </div>
        </div>
    )
}
