import React, { useEffect } from 'react';
import './NothingSelected.scss'

type Props = {
    setShowNothingSelectedModal: (boolean) => void
    t: any
}
export default function NothingSelectedModal({ setShowNothingSelectedModal, t }: Props) {
    useEffect(() => {
        $(`#nothingSelectedModal`).modal('show')
    }, [])

    return (
        <div className="modal" id="nothingSelectedModal" tabIndex={-1} role="dialog" >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('nothingSelected.header')}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setShowNothingSelectedModal(false)} data-dismiss="modal">{t('btn.ok')}</button>
                    </div>
                </div>
            </div>
        </div >
    )
}
