import React, { useState } from 'react';
import { formatDate } from '../../../models/api';
import { ItemType } from '../../../models/requiredItems';
import { Accommodation } from '../../types/accommodations';
import { Timing } from '../../types/booking';
import Accomodation from './Accomodation';

type Props = {
    accommodationsAll: Accommodation[] | undefined
    accommodations: Accommodation[] | undefined,
    incompatibilities: number[],
    timeslot: Timing | undefined
    earlyArrivalsEnabled: boolean,
    date: string,
    loading: boolean
    onNext: (e: any, nextStep?: number) => void
    onEarlyArrivalNoAnswer: () => void
    setAccomodations: (accommodation: Accommodation) => void,
    setUpgrades: (upgrade: Accommodation, cateringId: number) => void,
    updateItemInput: (value: number, item: ItemType, parentItem: ItemType, type: string) => void
    t: any
}
const buttomClassName = 'btn btn-link btn-block text-left'

export default function Accomodations({ accommodations, loading, accommodationsAll, incompatibilities, timeslot, earlyArrivalsEnabled, date, onNext, onEarlyArrivalNoAnswer, setAccomodations, setUpgrades, updateItemInput, t }: Props) {
    const [earlyArrivals, setEarlyArrivals] = useState<boolean>(false)
    const earlyArrivalDate = new Date(date)
    earlyArrivalDate.setDate(earlyArrivalDate.getDate() - 1)

    const accomodationProps = { accommodations, loading, accommodationsAll, incompatibilities, timeslot, setAccomodations, setUpgrades, updateItemInput, t }
    return (
        <div>
            <div className="accordion" id="accomodationAccordion">
                <Accomodation {...accomodationProps} accommodations={accommodations?.filter(ac => !ac.earlyArrival)} />
                {(earlyArrivalsEnabled && (accommodationsAll?.length || 0) > 0) && <div className="card" >
                    <div className="card-header" id="headingTwo">
                        <h2 className="mb-0">
                            <button className={buttomClassName} type="button" data-toggle="collapse" data-target={'#' + 'additionalRoom'} aria-controls={'additionalRoom'}>
                                {t('earlyArrival')}
                            </button>
                        </h2>
                    </div>
                    <div id={'additionalRoom'} className={`collapse show`} aria-labelledby="headingTwo" data-parent="#accordionExample">
                        <div className="card-body additionalRooms">
                            <h3>{t('earlyArrivalQuestion', { date: formatDate(earlyArrivalDate) })}</h3>
                            <div className="additionalRoomsControls">
                                <button type="button" className="btn btn-secondary" onClick={() => setEarlyArrivals(true)} data-dismiss="modal">{t('btn.yesPlease')}</button>
                                <button type="button" className="btn btn-secondary" onClick={(e) => {
                                    onNext(e)
                                    onEarlyArrivalNoAnswer()
                                    setEarlyArrivals(false)
                                }} data-dismiss="modal">{t('btn.noThanks')}</button>
                            </div>
                        </div>
                    </div>
                </div>}
                {earlyArrivals && <Accomodation {...accomodationProps} accommodations={accommodations?.filter(ac => ac.earlyArrival)} incompatibilities={[]} earlyArrival />}
            </div>
        </div>
    )
}
