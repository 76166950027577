import React, { useEffect } from 'react';
import './CopyFromPreviousDay.scss'

type Props = {
    t: any
    copyPreviousDay: (shouldCopyPreviousDay: boolean) => void
}
export default function CopyFromPreviousDayModal({ copyPreviousDay, t }: Props) {
    useEffect(() => {
        $(`#copyFromPreviousDayModal`).modal('show')
    }, [])

    return (
        <div className="modal" id="copyFromPreviousDayModal" tabIndex={-1} role="dialog" >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t('copyDay.header')}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => copyPreviousDay(true)} data-dismiss="modal">{t('btn.yesPlease')}</button>
                        <button type="button" className="btn btn-secondary" onClick={() => copyPreviousDay(false)} data-dismiss="modal">{t('btn.noThanks')}</button>
                    </div>
                </div>
            </div>
        </div >
    )
}
