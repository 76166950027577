import React, { useState, useEffect } from 'react';
import { MeetingRoom, MeetingRoomsResponse } from '../../types/meetingRooms';
import './MeetingRooms.scss';
import { ItemType } from '../../../models/requiredItems';
import MeetingRoomSelection from './MeetingRoom';
import Input from '../../utils/Input';
import { Timing } from '../../types/booking';

type Props = {
    meetingRoomsAll: MeetingRoomsResponse | undefined
    meetingRooms: MeetingRoom[],
    incompatibilities: number[],
    timeslot: Timing | undefined
    loading: boolean
    setMeetingRoom: (meetingRoom: MeetingRoom) => void,
    setSeatingLayout: (layout: string, id: number) => void,
    setUpgrades: (upgrade: MeetingRoom, meetingRoomId: number) => void,
    updateItemInput: (value: number, item: ItemType, parentItem: ItemType, type: string) => void
    onNext: (e: any, nextStep?: number) => void
    onAdditionalRoomsNoAnswer: () => void
    t: any
}
export const mainMeetingRoomType = ['main', 'all']
export const additionalMeetingRoomType = ['additional', 'all']
const buttomClassName = 'btn btn-link btn-block text-left'

export default function MeetingRooms({ meetingRooms, meetingRoomsAll, incompatibilities, timeslot, loading, t, setMeetingRoom, setSeatingLayout, setUpgrades, updateItemInput, onNext, onAdditionalRoomsNoAnswer }: Props) {
    const [filterSeatingLayout, setFilterSeatingLayout] = useState<string>('all')
    const [numberOfDesiredAdditionalRooms, setNumberOfDesiredAdditionalRooms] = useState<number>(-1) // number of additional MR requested by the user in the input
    const [additionalRoomsAttendies, setAdditionalRoomsAttendies] = useState<number[]>([])
    const [expandedAccordion, setExpandedAccordion] = useState(getExpandedAccordion())

    function getExpandedAccordion() {
        if (meetingRooms.length)
            return 'type'
        return 'meetingRoom'
    }

    const onMeetingRoomSelected = (room: MeetingRoom) => {
        const existingMainMeetingRoom = meetingRooms.find(c => c.type === "main");
        if (room.type === "main" && existingMainMeetingRoom && existingMainMeetingRoom.required) {
            return;
        }

        setMeetingRoom(room)
        const accordion = 'type' + room.id
        setExpandedAccordion(accordion)
        $('#' + accordion).collapse('show')
    }

    const onSetSeatingLayout = (layout: string, id: number) => {
        setSeatingLayout(layout, id)
        $('#roomUpgrades').collapse('show')
    }

    const meetingRoomProps = {
        loading, buttomClassName, meetingRooms, meetingRoomsAll, expandedAccordion, filterSeatingLayout, timeslot, incompatibilities,
        setFilterSeatingLayout, onMeetingRoomSelected, updateItemInput, onSetSeatingLayout, setUpgrades, allSelectedMeetingRooms: meetingRooms.map(mr => mr.id), t
    }

    const mainMeetingRoom = meetingRooms.find(mr => mr.type === 'main') || meetingRooms[0];
    const additionalMeetingRooms = meetingRooms.filter(mr => mr.type === 'additional');
    const availableAditionalRooms = meetingRoomsAll?.items.filter(item => item.meetingRoomType === 'additional' && mainMeetingRoom?.id !== item.id)
    let additionalRoomsArray = new Array(numberOfDesiredAdditionalRooms > -1 ? numberOfDesiredAdditionalRooms : 0).fill(undefined)

    useEffect(() => {
        if (mainMeetingRoom && additionalMeetingRooms?.length > 0 && numberOfDesiredAdditionalRooms === -1) {
            setNumberOfDesiredAdditionalRooms(additionalMeetingRooms.length)
            additionalRoomsArray = new Array(additionalMeetingRooms.length)
            additionalMeetingRooms.map((item, index) => {
                if (item.attendeesNo)
                    additionalRoomsAttendies[index] = item.attendeesNo
            })
        }

    }, [additionalMeetingRooms])

    if (!meetingRoomsAll)
        return <div />
    
    return (
        <div>
            <div className="accordion" id="accordionExample">
                <MeetingRoomSelection type="main" keyIndex={'main'} meetingRoom={mainMeetingRoom} {...meetingRoomProps} meetingRooms={[mainMeetingRoom]} />

                {mainMeetingRoom && (availableAditionalRooms?.length || 0) > 0 && 
                    <div className="card" >
                        <div className="card-header" id="headingTwo">
                            <h2 className="mb-0">
                                <button className={buttomClassName} type="button" data-toggle="collapse" data-target={'#' + 'additionalRoom'} aria-expanded={expandedAccordion === 'additionalRoom'} aria-controls={'additionalRoom'}>
                                    {t('additionalRoom')}
                                </button>
                            </h2>
                        </div>
                        <div id={'additionalRoom'} className={`collapse show`} aria-labelledby="headingTwo" data-parent="#accordionExample">
                            <div className="card-body additionalRooms">
                                <h3>{t('additionalRoomTitle')}</h3>
                                <div className="additionalRoomsControls">
                                    <button type="button" className="btn btn-secondary" disabled={additionalRoomsAttendies.length > 0 && additionalRoomsAttendies.some(attNumber => attNumber >= 0)} onClick={() => setNumberOfDesiredAdditionalRooms(1)} data-dismiss="modal">{t('btn.yesPlease')}</button>
                                    <button type="button" className="btn btn-secondary" onClick={(e) => {
                                        onNext(e)
                                        onAdditionalRoomsNoAnswer()
                                        setNumberOfDesiredAdditionalRooms(-1)
                                        setAdditionalRoomsAttendies([]);
                                    }} data-dismiss="modal">{t('btn.noThanks')}</button>
                                </div>
                                {numberOfDesiredAdditionalRooms >= 0 &&
                                    <div className="row">
                                        <Input
                                            type="number"
                                            size="xs"
                                            fullWidth
                                            min={1}
                                            max={availableAditionalRooms?.length}
                                            label={t('numberOfAditionalRooms')}
                                            value={numberOfDesiredAdditionalRooms}
                                            onChange={({ target: { value } }) => {
                                                let validatedValue = value ? Math.min(parseInt(value), availableAditionalRooms?.length || 0) : value
                                                setNumberOfDesiredAdditionalRooms(validatedValue)
                                                if (validatedValue <= 0) {
                                                    setNumberOfDesiredAdditionalRooms(-1)
                                                    onAdditionalRoomsNoAnswer();
                                                    setAdditionalRoomsAttendies([]);
                                                } else {
                                                    meetingRooms.filter(u => u.type === 'additional').slice(validatedValue).map(item => {
                                                        setMeetingRoom(item);
                                                    })
                                                    additionalRoomsAttendies.length = validatedValue;
                                                    setAdditionalRoomsAttendies(additionalRoomsAttendies)
                                                }
                                            }}
                                        />

                                        { numberOfDesiredAdditionalRooms > 0 &&
                                            <div className="col-sm-12">
                                                <label>{t('numberOfAtthendies.title')}</label>
                                            </div>
                                        }
                                        {numberOfDesiredAdditionalRooms > 0 &&
                                        additionalRoomsArray.map((item, index) => {
                                            return <div className="col-sm-4" key={index}>
                                                <Input
                                                    type="number"
                                                    size="sm"
                                                    fullWidth
                                                    min={1}
                                                    max={timeslot?.attendeesNo}
                                                    label={t('additionalRoomParticipants', { roomNumber: index + 1 })}
                                                    value={additionalRoomsAttendies[index]}
                                                    onChange={({ target: { value } }) => {
                                                        additionalRoomsAttendies[index] = parseInt(value)
                                                        const attendeesNo = timeslot?.attendeesNo
                                                        if (attendeesNo && additionalRoomsAttendies[index] > attendeesNo) {
                                                            additionalRoomsAttendies[index] = attendeesNo
                                                        }
                                                        setAdditionalRoomsAttendies([...additionalRoomsAttendies])
                                                    }}
                                                />
                                            </div>
                                        })}
                                    </div>}
                            </div>
                        </div>
                        {additionalRoomsArray.map((item, index) => {
                            const room = additionalMeetingRooms[index]
                            if (!additionalRoomsAttendies[index])
                                return
                            if (room) {
                                room.attendeesNo = additionalRoomsAttendies[index]
                            }
                            
                            return <MeetingRoomSelection type="additional" keyIndex={index} meetingRoom={room} {...meetingRoomProps} key={index} meetingRooms={additionalMeetingRooms || []} incompatibilities={[]} />
                        })}
                    </div>
                }
            </div>
        </div>
    )
}
