import React from 'react';
import CardItem from '../../card/CardItem';
import Filter from './Filter';
import { additionalMeetingRoomType, mainMeetingRoomType } from './MeetingRooms';
import './MeetingRoom.scss'
import noAvailableItems from '../../utils/NoAvailableItems';

export default function MeetingRoomSelection({ keyIndex, type, meetingRoom, meetingRooms, meetingRoomsAll, expandedAccordion, filterSeatingLayout, setFilterSeatingLayout,
    allSelectedMeetingRooms, timeslot, onMeetingRoomSelected, loading, updateItemInput, incompatibilities, buttomClassName, onSetSeatingLayout, setUpgrades, t }) {
    function renderError() {
        return <div className="alert alert-danger">{t('meetingRoomError')}</div>
    }
    const accordionIdSeatingLayout = `type${meetingRoom?.id || keyIndex}`
    const accordionIdMeetingRoom = `mr${meetingRoom?.id || keyIndex}`
    const allUpgrades = meetingRoomsAll?.items?.filter(item => item.sectionTypeOption === 'upgrade' && meetingRoom?.upgrades?.includes(item.id))

    if (!meetingRoomsAll?.items?.filter(u => u.meetingRoomType === 'all' || u.meetingRoomType === 'main')?.length && !loading) {
        return noAvailableItems(t)
    }

    return (
        <div>
            <div className="card">
                <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                        <button className={buttomClassName} type="button" data-toggle="collapse" data-target={`#${accordionIdMeetingRoom}`} aria-expanded="true" aria-controls={accordionIdMeetingRoom}>
                            {keyIndex == 'main' ? t('meetingRoomType') : t('additionalRoom#', { id: keyIndex + 1 })}
                        </button>
                    </h2>
                </div>

                <div id={accordionIdMeetingRoom}
                    className={`collapse ${expandedAccordion === 'meetingRoom' || (type !== 'main' || !meetingRoom) ? 'show' : ''}`}
                    aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div className="card-body row card-list">
                        <Filter seatingLayout={filterSeatingLayout} seetingLayouts={meetingRoomsAll?.filters?.seatingLayouts} setFilterLayout={setFilterSeatingLayout} all t={t} />
                        {meetingRoomsAll?.items
                            .filter(item => (type === 'main' ? mainMeetingRoomType : additionalMeetingRoomType).includes(item.meetingRoomType) && (filterSeatingLayout === 'all' || !!item.seatingLayouts.find(layout => layout.layout === filterSeatingLayout)))
                            .map(room => {
                                const selected = meetingRooms?.find(item => item?.id === room.id)
                                return <CardItem
                                    key={room.id}
                                    item={selected || room}
                                    type="meetingrooms"
                                    onSelect={(selected) => {
                                        onMeetingRoomSelected(Object.assign({type: type}, selected))
                                    }}
                                    updateItemInput={updateItemInput}
                                    checked={!!selected}
                                    timeslot={timeslot}
                                    t={t}
                                    disabled={incompatibilities.includes(room.id) 
                                        || (allSelectedMeetingRooms.includes(room.id) && room.id !== meetingRoom?.id)
                                        || (meetingRoom && type !== 'main' && room.id !== meetingRoom?.id)}
                                    numberOfAttendies={type === 'additional'} />
                            })}
                    </div>
                </div>
            </div>

            {meetingRoom && meetingRoom.seatingLayouts.length > 0 && <div className="card">
                <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                        <button className={buttomClassName} type="button" data-toggle="collapse" data-target={'#' + accordionIdSeatingLayout} aria-expanded={expandedAccordion === accordionIdSeatingLayout} aria-controls={accordionIdSeatingLayout}>
                            {t('seatingLayout', { name: meetingRoom?.name })}
                        </button>
                    </h2>
                </div>
                <div id={accordionIdSeatingLayout} className={`collapse ${expandedAccordion === accordionIdSeatingLayout ? 'show' : ''}`} aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div className="card-body seatingLayout">
                        {meetingRooms ? <Filter
                            seatingLayout={meetingRoom?.seatingLayout}
                            seetingLayouts={meetingRoom?.seatingLayouts}
                            setFilterLayout={(layout) => onSetSeatingLayout(layout, meetingRoom?.id)}
                            all={false} 
                            t={t} />
                            : renderError()}
                    </div>
                </div>
            </div>}

            {!!allUpgrades?.length && <div className="card" key={meetingRoom?.id}>
                <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                        <button className={buttomClassName} type="button" data-toggle="collapse" data-target={`#upgrades_${accordionIdMeetingRoom}`} aria-expanded="false" aria-controls="upgrades">
                            {t('upgrades', { name: meetingRoom?.name })}
                        </button>
                    </h2>
                </div>
                <div id={`upgrades_${accordionIdMeetingRoom}`} className="collapse show" aria-labelledby="headingThree" data-parent="#accordionExample">
                    <div className="card-body row card-list">
                        {allUpgrades?.map(upgrade => {
                            const selected = meetingRoom?.upgradeItems?.find(u => u.id === upgrade.id)
                            return <CardItem
                                key={upgrade.id}
                                item={selected || upgrade}
                                parentItem={Object.assign({type: type}, meetingRoom)}
                                type="meetingrooms"
                                timeslot={timeslot}
                                onSelect={(selected) =>
                                    setUpgrades(Object.assign({type: type}, selected), meetingRoom?.id)
                                }
                                updateItemInput={updateItemInput}
                                checked={selected?.id === upgrade.id} 
                                t={t}
                                disabled={incompatibilities.includes(upgrade.id)} />
                        })}
                    </div>
                </div>
            </div>}
        </div>)
}
