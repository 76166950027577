import React, { useEffect, useState } from 'react';
import { useSettings } from '../../SettingsContext';
import { formatDate } from '../../models/api';
import { getNextDays } from '../../models/utils';
import './Timeline.scss';

type props = {
    step: number
    duration: number | undefined
    date: string | undefined
    onStepSelect: (index) => void
    quoteStepEnabled: Boolean,
    quoteStepIndex: number,
    t: any
}
export default function Timeline({ step, duration, date, onStepSelect, quoteStepEnabled, quoteStepIndex, t }: props) {
    const [settings] = useSettings();
    const { dates } = getNextDays(date, settings?.maxDurationDays);
    const [maxStep, setMaxStep] = useState(step);
    
    useEffect(() => {
        if (step > maxStep) {
            setMaxStep(step)
        }
    }, [step, maxStep])

    const renderStep = (index: number, title: string) => {
        const disabled = maxStep < index || (index === quoteStepIndex && !quoteStepEnabled)
        return (
            <div
                className={`timeline-segment ${step === index ? 'active' : ''}  ${disabled ? 'disabled' : ''}`}
                onClick={() => {
                    if (!disabled)
                        onStepSelect(index)
                }} key={index}>
                <span className="timeline-point">
                </span><label className="timeline-label">{!title.includes(',') ? t(title)
                    : `${t('day')} ${title}`}</label>
            </div>
        )
    }
    return (
        <div className="timeline">
            {renderStep(1, 'start')}
            {renderStep(2, (!duration ? 'details' : `1, ${formatDate(dates[0])}`))}
            {dates.map((date, index) => {
                if (index > 0 && index < (duration || 1))
                    return renderStep(index + 2, (`${index + 1}, ${formatDate(date)}`))
            })}
            {renderStep(quoteStepIndex, 'quote')}
        </div>
    );
}
