import { RequiredItem } from '../components/types/meetingRooms';
import { Catering } from '../components/types/caterings';

export type ItemType = {
    id: number
    required: boolean
    earlyArrival?: boolean
    type: 'main' | 'additional'
    upgradeItems: ItemType[]
    requiredItems: RequiredItem[],
    incompatibilities: ItemType
    sectionTypeOption: 'option' | 'upgrade'
}

export type Details = {
    meetingRooms,
    caterings,
    accommodations,
    activities
}

export const itemTypes = ['meetingRooms', 'caterings', 'accommodations', 'activities']
export async function addRequiredItems(newItem: ItemType, selected: Details, allItems: Details) {
    let modified = false;
    const requiredItems = newItem?.requiredItems;
    if (!requiredItems) {
        return false;
    }

    for (let type of itemTypes) {
        for (let reqItem of requiredItems[type]) {
            const requiredItem = getRequiredItem(reqItem, selected[type], allItems[type])
            if (requiredItem) {
                if (requiredItem.sectionTypeOption === 'option') {
                    const exist = selected[type]?.find(i => i.id === requiredItem.id)
                    if (!exist) {
                        if (type === "meetingRooms") {
                            requiredItem.type = "main";
                            selected[type] = [];
                        }

                        if (type === "caterings" && selected[type].length > 0) {
                            if (selected[type][0].cateringType !== (requiredItem as unknown as Catering)?.cateringType) {
                                selected[type] = [];
                            }
                        }

                        selected[type].push(requiredItem)
                        modified = true
                    }
                    else {
                        exist.required = true
                    }
                } else {
                    for (let item of selected[type]) {
                        const exist = selected[type]?.upgradeItems?.find(i => i.id === requiredItem.id)
                        if (!exist && item.upgrades?.includes(requiredItem.id)) {
                            if (!item.upgradeItems) {
                                item.upgradeItems = [];
                            }

                            let existingUpgradeItem = item.upgradeItems.find(u => u.id === requiredItem.id);
                            if (existingUpgradeItem) {
                                existingUpgradeItem.required = true;
                            }
                            else {
                                item.upgradeItems.push(requiredItem);
                            }
                           
                            modified = true;
                        }
                    }
                }
                if (requiredItem.requiredItems) {
                    const newSelected = await addRequiredItems(requiredItem, selected, allItems)
                    if (newSelected) {
                        selected = newSelected;
                        modified = true;
                    }
                }
            }
        }
    }
    if (!modified) {
        return false;
    }

    return selected;
}

function getRequiredItem(item: ItemType, selectedItems: ItemType[], allItems: ItemType[]): ItemType | undefined {
    const exist = selectedItems.find(i => i.id === item.id)
    if (exist)
        return { ...exist, required: true, upgradeItems: [] }

    const ob = allItems.find(i => i.id === item.id) as ItemType
    return { ...ob, required: true, upgradeItems: [] }
}

export async function removeRequiredItems(newItem: ItemType, selected: Details, allItems: Details) {
    let modified = false;
    const requiredItems = newItem.requiredItems
    if (!requiredItems)
        return false

    for (let type of itemTypes) {
        for (let reqItem of requiredItems[type]) {
            const index = selected[type].findIndex(i => i.id === reqItem.id)
            if (index > -1) {
                selected[type][index].required = false;
                selected[type].splice(index, 1);
                modified = true
            }
        }
    }
    if (!modified) {
        return false;
    }

    return selected;
}

