import React from 'react';
import CardItem from '../../card/CardItem';
import { Timing } from '../../types/booking';
import { Activity } from '../../types/activities';
import { ItemType } from '../../../models/requiredItems';
import noAvailableItems from '../../utils/NoAvailableItems';

type Props = {
    activitiesAll: Activity[] | undefined
    activities: Activity[] | undefined,
    incompatibilities: number[],
    timeslot: Timing | undefined
    loading: boolean
    setActivities: (cateringTypes: Activity) => void,
    setUpgrades: (upgrade: Activity, cateringId: number) => void,
    updateItemInput: (value: number, item: ItemType, parentItem: ItemType, type: string) => void
    t: any
}
const buttomClassName = 'btn btn-link btn-block text-left'

export default function Activities({ activities, activitiesAll, incompatibilities, timeslot, loading, setActivities, setUpgrades, updateItemInput, t }: Props) {
    const onActivitySelected = (activity: Activity) => {
        setActivities(activity)
    }

    if (!activitiesAll?.filter(u => u.sectionTypeOption === 'option')?.length && !loading) {
        return noAvailableItems(t)
    }

    return (
        <div>
            <div className="accordion" id="activityAccordion">
                <div className="card">
                    <div className="card-header" id="headingTwo">
                        <h2 className="mb-0">
                            <button className={buttomClassName} type="button" data-toggle="collapse" data-target="#accomodation" aria-expanded="false" aria-controls="accomodation">
                                {t('chooseActivities')}
                            </button>
                        </h2>
                    </div>
                    <div id="accomodation" className={`collapse  show`} aria-labelledby="headingTwo" data-parent="#accordionExample">
                        <div className="card-body row card-list">
                            {activitiesAll?.filter(item => item.sectionTypeOption === 'option')
                                .map(activity => {
                                    const selected = activities?.find(item => item.id === activity.id)
                                    return <CardItem
                                        key={activity.id}
                                        item={selected || activity}
                                        type="activities"
                                        timeslot={timeslot}
                                        onSelect={onActivitySelected}
                                        updateItemInput={updateItemInput}
                                        checked={!!selected}
                                        t={t}
                                        disabled={incompatibilities.includes(activity.id)} />
                                })}
                        </div>
                    </div>
                </div>
                {activities?.map(activity => {
                    const allUpgrades = activitiesAll?.filter(item => item.sectionTypeOption === 'upgrade' && activity.upgrades?.includes(item.id))
                    if (!allUpgrades?.length) return

                    return (<div className="card" key={activity.id}>
                        <div className="card-header" id="headingThree">
                            <h2 className="mb-0">
                                <button className={buttomClassName} type="button" data-toggle="collapse" data-target="#upgrades" aria-expanded="false" aria-controls="upgrades">
                                    {t('upgrades', { name: activity.name })}
                                </button>
                            </h2>
                        </div>
                        <div id="upgrades" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div className="card-body row card-list">
                                {allUpgrades?.map(upgrade => {
                                    const selected = activity.upgradeItems?.find(u => u.id === upgrade.id)
                                    return <CardItem
                                        key={upgrade.id}
                                        item={selected || upgrade}
                                        parentItem={activity}
                                        type="activities"
                                        timeslot={timeslot}
                                        onSelect={() => setUpgrades(upgrade, activity.id)}
                                        updateItemInput={updateItemInput}
                                        checked={selected?.id === upgrade.id} t={t}
                                        disabled={incompatibilities.includes(upgrade.id)} />
                                })}
                            </div>
                        </div>
                    </div>)
                })}
            </div>
        </div>
    )
}
