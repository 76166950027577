import React from 'react';

export default function PageNotFound({ t }) {

    return (
        <div className="pageNotFound">
            <h1>Page not found</h1>
        </div>
    )
}
