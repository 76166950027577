import './CardItem.scss'
import Modal from './Modal';
import Input from '../utils/Input';
import { Timing } from '../types/booking';
import { getImageUrl } from '../../models/api';
import React, { useEffect, useState } from 'react';
import { getRecommendedValue, ItemType } from '../../models/utils';

const defaultImage = require('./../../resouces/image-placeholder.jpg')

type Props = {
    item: ItemType,
    parentItem?: ItemType,
    checked?: boolean
    t: any
    type: string,
    disabled?: boolean
    timeslot: Timing | undefined
    numberOfAttendies?: boolean
    onSelect: (item) => void
    updateItemInput: (value: number, item, parentItem, type: string) => void
}

export default function CardItem({ item, parentItem, type, checked, t, disabled, numberOfAttendies, timeslot, onSelect, updateItemInput }: Props) {
    const attendeesNo = timeslot?.attendeesNo || 1
    const [error, setError] = useState<boolean>()
    const [showModal, setShowModal] = useState(false)
    const [inputValue, setInputValue] = useState(getRecommendedValue(item, attendeesNo, parentItem))

    useEffect(() => {
        if (showModal)
            $(`#card-modal-${item.id}`).modal('show')
    }, [showModal, item.id])

    const onClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setShowModal(true)
    }

    const onClose = () => {
        setShowModal(false)
    }

    const selectItem = () => {
        if (!disabled && !item.required) {
            const recommendedValue = getRecommendedValue(item, attendeesNo, parentItem)
            setInputValue(recommendedValue)
            item.inputValue = recommendedValue || inputValue as any
            onSelect(item)
        }
    }
    function validateInput(value: number) {
        if (!item.adjustment) return true
        let { maxAdjustmentNumber, minAdjustmentNumber } = item.adjustment
        
        if (maxAdjustmentNumber && maxAdjustmentNumber > attendeesNo) {
            maxAdjustmentNumber = attendeesNo
        }

        if (minAdjustmentNumber && minAdjustmentNumber > attendeesNo) {
            minAdjustmentNumber = attendeesNo
        }

        return !(value < (minAdjustmentNumber || 1) || value > (maxAdjustmentNumber || 9999999))
    }
    const onInputChange = (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        let { target: { value } } = e
        if (value)
            value = parseInt(value) || 1

        const error = !validateInput(value)
        //setError(error)
        if (!error)
            updateItemInput(value, item, parentItem, type)
        
        setInputValue(value)
    }

    const onInputBlur = (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        let { target: { value } } = e
        if (value)
            value = parseInt(value) || 1

        if (item.adjustment) {
            let { maxAdjustmentNumber, minAdjustmentNumber } = item.adjustment
            if (maxAdjustmentNumber && maxAdjustmentNumber > attendeesNo) {
                maxAdjustmentNumber = attendeesNo
            }
    
            if (minAdjustmentNumber && minAdjustmentNumber > attendeesNo) {
                minAdjustmentNumber = attendeesNo
            }
    
            if (maxAdjustmentNumber && value > maxAdjustmentNumber) {
                value = maxAdjustmentNumber
            }
            else if (minAdjustmentNumber && value < minAdjustmentNumber) {
                value = minAdjustmentNumber
            }
        }

        if (parentItem && value > parentItem.inputValue) {
            value = parentItem.inputValue
        }

        setInputValue(value)
    }

    const itemType = type
    const renderInputField = () => {
        if ((!item.adjustment && !numberOfAttendies) || (itemType === 'meetingrooms' && item['type'] !== 'main' && item.sectionTypeOption !== 'upgrade'))
            return
        let { maxAdjustmentNumber, minAdjustmentNumber, type } = item.adjustment || {}
        
        if (maxAdjustmentNumber && maxAdjustmentNumber > attendeesNo) {
            maxAdjustmentNumber = attendeesNo
        }

        if (minAdjustmentNumber && minAdjustmentNumber > attendeesNo) {
            minAdjustmentNumber = attendeesNo
        }

        let constraints = ''
        let errorMessage = ''
        if (maxAdjustmentNumber) {
            constraints = t('from-to', { min: minAdjustmentNumber || 1, max: maxAdjustmentNumber })
            errorMessage = t('errorMinMax', { min: minAdjustmentNumber || 1, max: maxAdjustmentNumber })
        } else if (minAdjustmentNumber) {
            constraints = t('min', { min: minAdjustmentNumber })
            errorMessage = t('errorMin', { min: minAdjustmentNumber })
        }

        if (numberOfAttendies) {
            type = 'participants'
        }

        return (
            <div className="row">
                <Input
                    type="text"
                    size="xs"
                    fullWidth
                    min={minAdjustmentNumber}
                    max={maxAdjustmentNumber}
                    label={t(type) + constraints}
                    value={disabled || !checked ? '' : inputValue}
                    onChange={onInputChange}
                    onBlur={onInputBlur}
                    disabled={disabled || !checked}
                    error={error ? errorMessage : ''}
                />
            </div>)
    }

    const image = item.images[0] ? getImageUrl(type, item.images[0]?.name, 'medium') : defaultImage
    return (
        <div className={`card card-item ${item.required ? 'required' : ''} ${disabled ? 'disabled' : ''}`} >
            <img src={image} className="card-img-top" alt="..." onClick={selectItem} />
            {!disabled && <div className={`checkbox ${checked ? 'checked' : ''}`} onClick={selectItem} />}
            <div className="card-body" >
                <h5 className="card-title">{item.name}</h5>
                <p className="short-desc" dangerouslySetInnerHTML={{ __html: item.description }} />
                <div className="row">
                    <div className="col-sm-12">
                        <button type="button" className="btn btn-outline-secondary btn-sm float-right" data-toggle="modal" data-target="#card-modal" onClick={onClick}>{t('more')}</button>
                    </div>
                </div>

                {item.required && <p className="requiredLabel">{t('required.label')}</p>}
                {disabled && <p className="disabledLabel">{t('incompatible.label')}</p>}
                {renderInputField()}
            </div>
            {showModal && <Modal item={item} onClose={onClose} type={type} t={t} />}
        </div>
    )
}
