import { Accommodation } from '../components/types/accommodations';
import { Activity } from '../components/types/activities';
import { Catering } from '../components/types/caterings';
import { MeetingRoom } from '../components/types/meetingRooms';
import { formatDate } from './api';

export type SelectionChangeItem = {
    item: MeetingRoom | Catering | Accommodation | Activity
    exist: boolean
}

export type ItemType = MeetingRoom | Catering | Accommodation | Activity
export const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export function getDateString(date) {
    const locale = getLang()
    const fullMonth = date.toLocaleDateString(locale, { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' });
    return fullMonth
}

export function formatPriceString(priceValue) {
    if (!priceValue) {
        priceValue = 0;
    }

    return priceValue.toLocaleString(getLang(), {minimumFractionDigits: 2, maximumFractionDigits: 2});
}

export function monthDiff(dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}

export function getNextDays(startDate: string = '', maxDurationDays: number = 1) {
    if (!startDate) {
        return { dates: [] };
    }

    return { dates: [...Array(maxDurationDays)].map((_, i) => {
        const date = new Date(startDate);
        date.setDate(date.getDate() + i);
        return new Date(date);
    })};
}

export function getTimings(timingsArray, startDate: string = '', maxDurationDays: number = 1) {
    const timings = {};
    if (timingsArray) {
        const { dates } = getNextDays(startDate, maxDurationDays);
        timings[formatDate(dates[0])] = timingsArray[0];
        if (timingsArray[1])
            timings[formatDate(dates[1])] = timingsArray[1];
        if (timingsArray[2])
            timings[formatDate(dates[2])] = timingsArray[2];
        if (timingsArray[3])
            timings[formatDate(dates[3])] = timingsArray[3];
        if (timingsArray[4])
            timings[formatDate(dates[4])] = timingsArray[4];
    }

    return timings;
}

export function getTotalMinutesFromTime(time: string, isPlusOneDay = false) {
    if (!time)
        return 0

    const hour = parseInt(time.substring(0, 2))
    let minutes = hour * 60
    minutes += parseInt(time.substring(3, 5))

    if (isPlusOneDay && hour > 0 && hour < 12)
        minutes += 24 * 60

    return minutes
}

export function getRangeIndex(time, isPlusOneDay: boolean, isEndTime: boolean) {
    if (!time) return 0
    let hour = parseInt(time.substring(0, 2))
    if (isPlusOneDay) hour += 12
    hour = hour % 24

    let index = hour * 2

    if (parseInt(time.substring(3, 5)) >= 30)
        index++

    if (isEndTime && index === 0)
        index = 48

    return index
}

export function getTimeFromRangeIndex(index, isPlusOneDay, originalStartTime) {
    let hour = Math.floor(index / 2)

    let minutesOffset = parseInt(originalStartTime.substring(3, 5))
    if (minutesOffset >= 30)
        minutesOffset -= 30

    if (isPlusOneDay)
        hour += 12

    hour = hour % 24

    let minutes = (index % 2 === 1) ? 30 : 0

    minutes += minutesOffset
    if (minutes === 60)
        minutes = 0

    return `${(hour < 10) ? '0' : ''}${hour}:${(minutes < 10) ? '0' : ''}${minutes}`
}

export function deepCopy(ob: any) {
    return JSON.parse(JSON.stringify(ob))
}

export function parseQuery(queryString) {
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

export function getLang() {
    const languages = ['en', 'de']
    const queryparams: any = parseQuery(window.location.search)
    let lang = queryparams.lang || 'en'
    if (!languages.includes(lang)) {
        return 'en'
    }
    return lang
}

function getValueMinMax(value: number, min: number, max: number, attendeesNo: number, isGuestAdjusment: boolean) {
    value = Math.max(value, min || 1)
    if (max)
        value = Math.min(value, max)
    
    if (isGuestAdjusment && value > attendeesNo) {
        value = attendeesNo
    }

    return value
}

export function getRecommendedValue(item: ItemType, attendeesNo: number, parentItem?: ItemType) {
    if (parentItem?.inputValue) 
        return parentItem.inputValue

    if (item.inputValue)
        return item.inputValue

    if (!item.adjustment)
        return
        
    let recommendedValue
    let { maxAdjustmentNumber, minAdjustmentNumber } = item.adjustment || {}

    if (item.paxPerUnit && item.adjustment.type === "unitNumber") {
        recommendedValue = Math.ceil(attendeesNo / item.paxPerUnit)
    } else if (item.adjustment.type == "guestNumber") {
        if (parentItem && (<MeetingRoom>parentItem)?.attendeesNo) {
            recommendedValue = (<MeetingRoom>parentItem).attendeesNo
        }
        else {
            recommendedValue = attendeesNo
        }
    }
    else {
        recommendedValue = 1
    }

    if (parentItem && recommendedValue > parentItem.inputValue) {
        recommendedValue = parentItem.inputValue;
    }

    return getValueMinMax(recommendedValue, minAdjustmentNumber, maxAdjustmentNumber, attendeesNo, item.adjustment?.type === "guestNumber")
}

export function getSelectionChangeItems(itemTypes: any[], exist) {
    let selectionChangeItems = new Array<SelectionChangeItem>();

    itemTypes.map(i => {
        selectionChangeItems.push({ item: i, exist: exist });
    });

    return selectionChangeItems;
}