import React from 'react';
import './InquerySuccess.scss';

export default function InquerySuccess({ startOver, t }) {

    return (
        <div className="inquerySuccess">
            <h1>{t('inquery.checkEmail')}</h1>
            <h2>
                {t('inquery.successTitle')}
                <br />
                <br />
                {t('inquery.successDescription')}
                <br />
                {t('inquery.successAction')}
            </h2>
            <button type="button" className="btn btn-primary getQuote" onClick={startOver}>{t('startOver')}</button>
        </div>
    )
}
