import React from 'react';
import { ItemType } from '../../../models/requiredItems';
import CardItem from '../../card/CardItem';
import { Accommodation } from '../../types/accommodations';
import { Timing } from '../../types/booking';
import noAvailableItems from '../../utils/NoAvailableItems';

type Props = {
    accommodationsAll: Accommodation[] | undefined
    accommodations: Accommodation[] | undefined,
    incompatibilities: number[],
    timeslot: Timing | undefined
    earlyArrival?: boolean
    loading: boolean
    setAccomodations: (accommodation: Accommodation) => void,
    setUpgrades: (upgrade: Accommodation, cateringId: number) => void,
    updateItemInput: (value: number, item: ItemType, parentItem: ItemType, type: string) => void
    t: any
}
const buttomClassName = 'btn btn-link btn-block text-left'

export default function Accomodations({ accommodations, accommodationsAll, incompatibilities, earlyArrival, loading, timeslot, setAccomodations, setUpgrades, updateItemInput, t }: Props) {
    const onAccommodationSelected = (accommodation: Accommodation) => {
        setAccomodations(accommodation)
    }
    if (!accommodationsAll?.filter(u => u.sectionTypeOption === 'option')?.length && !loading) {
        return noAvailableItems(t)
    }
    const accordionId = `accomodation${earlyArrival && 'earlyArrival'}`
    return (
        <div>
            <div className="card">
                <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                        <button className={buttomClassName} type="button" data-toggle="collapse" data-target={`#${accordionId}`} aria-expanded="false" aria-controls={accordionId}>
                            {t(earlyArrival ? 'chooseEarlyArrivalAccommodation' : 'chooseAccommodation')}
                        </button>
                    </h2>
                </div>
                <div id={accordionId} className={`collapse  show`} aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div className="card-body row card-list">
                        {accommodationsAll?.filter(item => item.sectionTypeOption === 'option')
                            .map(accommodation => {
                                const selected = accommodations?.find(item => item.id === accommodation.id)

                                return <CardItem
                                    key={accommodation.id}
                                    item={selected || accommodation}
                                    type="accommodations"
                                    timeslot={timeslot}
                                    onSelect={(selected) => {
                                        onAccommodationSelected(Object.assign({earlyArrival: earlyArrival || false}, selected))
                                    }}
                                    updateItemInput={updateItemInput}
                                    checked={!!selected}
                                    t={t}
                                    disabled={incompatibilities.includes(accommodation.id)} />
                            })}
                    </div>
                </div>
            </div>
            {accommodations?.map(accommodation => {
                const allUpgrades = accommodationsAll?.filter(item => item.sectionTypeOption === 'upgrade' && accommodation.upgrades?.includes(item.id))
                if (!allUpgrades?.length) return

                return (<div className="card" key={accommodation.id}>
                    <div className="card-header" id="headingThree">
                        <h2 className="mb-0">
                            <button className={buttomClassName} type="button" data-toggle="collapse" data-target="#upgrades" aria-expanded="false" aria-controls="upgrades">
                                {t('upgrades', { name: accommodation.name })}
                            </button>
                        </h2>
                    </div>
                    <div id="upgrades" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionExample">
                        <div className="card-body row card-list">
                            {allUpgrades?.map(upgrade => {
                                const selected = accommodation.upgradeItems?.find(u => u.id === upgrade.id)

                                return <CardItem
                                    key={upgrade.id}
                                    item={selected || upgrade}
                                    parentItem={Object.assign({earlyArrival: earlyArrival || false}, accommodation)}
                                    type="accommodations"
                                    timeslot={timeslot}
                                    onSelect={(selected) => {
                                        setUpgrades(Object.assign({earlyArrival: earlyArrival || false}, selected), accommodation.id)
                                    }}
                                    updateItemInput={updateItemInput}
                                    checked={selected?.id === upgrade.id}
                                    t={t}
                                    disabled={incompatibilities.includes(upgrade.id)} />
                            })}
                        </div>
                    </div>
                </div>)
            })}
        </div>
    )
}
