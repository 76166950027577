import React, { useEffect, useState } from "react";
import {
  fetchInquiryAcceptedInfo,
  acceptInquiryOffer,
  formatDate,
  formatUtcDateTime,
} from "../../models/api";
import { InquiryInfo } from "../types/inquery";
import Loader from "react-loader-spinner";
import "./InqueryAcceptedInfo.scss";

export default function InqueryAcceptedInfo({ v, refId, startOver, t }) {
  const [inqInfo, setInqInfo] = useState<InquiryInfo>();
  const [loading, setLoading] = useState<boolean>(true);
  const [hasError, setError] = useState<number>();

  useEffect(() => {
    fetchInqInfo(v, refId);
  }, [v, refId]);

  const fetchInqInfo = async (v: string, refId: string) => {
    var inqInfoRez = await fetchInquiryAcceptedInfo(v, refId);
    if (!!inqInfoRez) {
      if (inqInfoRez.status && inqInfoRez.status === 404) {
        setError(404);
      } else {
        if (inqInfoRez.acceptedOn) {
          setInqInfo(inqInfoRez);
        } else {
          var result = await acceptInquiryOffer(v, refId);
          if (!result.err) {
            var inqInfoRez = await fetchInquiryAcceptedInfo(v, refId);
            setInqInfo(inqInfoRez);
          } else {
            setError(2);
          }
        }
      }
    } else {
      setError(1);
    }

    setLoading(false);
  };

  return (
    <>
      {loading && (
        <div className="card-body row loading">
          <Loader type="ThreeDots" color="#000000" height={40} width={40} />
        </div>
      )}
      {!loading && !!inqInfo && !!inqInfo.acceptedOn && (
        <div className="inqueryAcceptedInfo">
          <h1>{t("inquiry.acceptedInfoTitle")}</h1>
          <h2>
            {t("inquiry.acceptedInfoOnAcceptTextLine1", {
              acceptedOn: formatUtcDateTime(new Date(inqInfo.acceptedOn)),
            })}
            <br />
            <br />
            {t("inquiry.acceptedInfoOnAcceptTextLine2", {
              startDate: formatDate(new Date(inqInfo.startDate)),
              duration: inqInfo.duration,
            })}
            <br />
            <br />
            <br />
            <button
              type="button"
              className="btn btn-primary"
              onClick={startOver}
            >
              {t("startOver")}
            </button>
          </h2>
        </div>
      )}
      {!loading && hasError && hasError === 404 && (
        <div className="inqueryAcceptedInfo">
          <h1>{t("inquiry.acceptedInfoNotFound")}</h1>
          <br />
        </div>
      )}
      {!loading &&
        hasError &&
        hasError === 2 && ( // was found but counldn't accept it
          <div className="inqueryAcceptedInfo">
            <h1>{t("inquiry.acceptedInfoCannotAcceptTitle")}</h1>
            <h2>{t("inquiry.acceptedInfoCannotAccept")}</h2>
            <br />
          </div>
        )}
      {!loading &&
        hasError &&
        hasError === 1 && ( // unexpected error
          <div className="inqueryAcceptedInfo">
            <h1>{t("inquiry.acceptedInfoCannotAcceptTitle")}</h1>
            <br />
            <br />
          </div>
        )}
    </>
  );
}
