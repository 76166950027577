import './DetailsStep.scss';
import Loader from 'react-loader-spinner';
import PriceComponent from './price/Price';
import { Booking } from '../types/booking';
import { useSettings } from '../../SettingsContext';
import Caterings from './caterings/Caterings';
import { Catering } from '../types/caterings';
import { Activity } from '../types/activities';
import Activities from './activities/Activities';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import MeetingRooms from './meetingRooms/MeetingRooms';
import { Accommodation } from '../types/accommodations';
import { SelectionChangeItem, getNextDays, getRecommendedValue, getSelectionChangeItems } from '../../models/utils';
import Accomodations from './accommodations/Accomodations';
import { fetchSectionsData, formatDate } from '../../models/api';
import CopyFromPreviousDayModal from './modal/CopyFromPreviousDay';
import { returnIncompatibitities } from '../../models/incompatibilities';
import { MeetingRoom, MeetingRoomsResponse } from '../types/meetingRooms';
import { addUpgrade, toggleTab, updateInputValue, validateNext } from '../../models/details';
import { addRequiredItems, Details, ItemType, removeRequiredItems } from '../../models/requiredItems';

type IncompatibilitiesType = {
    meetingRooms: number[]
    caterings: number[]
    accommodations: number[]
    activities: number[]
}

type Props = {
    t: any
    booking: Booking | undefined
    day: number
    //setBookingDetails: (details: Details, date: string, goBack?: boolean) => void
    onStepSelect: (changeToStep: number) => void
    previousStep: number
    
}
const menus = [
    'meetingRooms',
    'caterings',
    'accommodations',
    'activities'
]

export const DetailsStep = forwardRef(({ booking, day, onStepSelect, previousStep, t }: Props, ref) => {
    const [settings] = useSettings();
    const priceSettings = settings?.price;
    const { dates } = getNextDays(booking?.date, settings?.maxDurationDays)
    const date = dates[day - 1]
    const timeslot = booking?.timings[day - 1]
    const [tabInternalStep, setTabInternalStep] = useState(0)
    const [loading, setLoading] = useState(true)
    const [shouldCopyPreviousDay, setShouldCopyPreviousDay] = useState(false)
    const [showCopyDay, setShowCopyDay] = useState(false) // show it only onice
    const [error, setError] = useState('')

    const [newIncompatibilities, setNewIncompatibilities] = useState(false);
    const [incompatibilities, setIncompatibilities] = useState<IncompatibilitiesType>({
        meetingRooms: [],
        caterings: [],
        accommodations: [],
        activities: []
    })
     
    const [meetingRooms, setMeetingRooms] = useState<MeetingRoom[]>(timeslot?.details?.meetingRooms || new Array<MeetingRoom>())
    const [cateringType, setCateringType] = useState<string | null>(null);
    const [caterings, setCaterings] = useState<Catering[]>(timeslot?.details?.caterings || new Array<Catering>())
    const [accommodations, setAccommodations] = useState<Accommodation[]>(timeslot?.details?.accommodations || new Array<Accommodation>())
    const [activities, setActivities] = useState<Activity[]>(timeslot?.details?.activities || new Array<Activity>())

    const [meetingRoomsAll, setMeetingRoomsAll] = useState<MeetingRoomsResponse>()
    const [cateringsAll, setCateringsAll] = useState<Catering[]>([])
    const [accommodationsAll, setAccommodationsAll] = useState<Accommodation[]>()
    const [activitiesAll, setActivitiesAll] = useState<Activity[]>()

    const [selectionChangeItems, setSelectionChangeItems] = useState<SelectionChangeItem[]>(new Array<SelectionChangeItem>())

    const getQuoteEnabled = meetingRooms.length > 0 || caterings.length > 0 || accommodations.length > 0 || activities.length > 0

    useImperativeHandle(ref, () => ({
        onNextRequested() {
            //submitDay()
            return {
              details:  { meetingRooms, caterings, accommodations, activities }, 
              date: formatDate(date)
            }
        }
    }));

    useEffect(() => {
        const selected = { meetingRooms, caterings, accommodations, activities }
        const incompatibilities = returnIncompatibitities(selected);
        setIncompatibilities(incompatibilities)
        if (incompatibilities && 
            (
                incompatibilities.meetingRooms.length > 0 || incompatibilities.caterings.length > 0 ||
                incompatibilities.accommodations.length > 0 || incompatibilities.activities.length > 0
            )
        ) {
            setNewIncompatibilities(true);
        }
        
    }, [meetingRooms, caterings, accommodations, activities])

    useEffect(() => {
        if (newIncompatibilities) {
            incompatibilities.meetingRooms.map(incompatibilityId => {
                meetingRooms.map(item => {
                    item.upgradeItems = item.upgradeItems?.filter(c => c.id !== incompatibilityId || c.type === "additional");
                });

                setMeetingRooms(meetingRooms.filter(c => c.id !== incompatibilityId || c.type === "additional"))
            });
    
            incompatibilities.caterings.map(incompatibilityId => {
                caterings.map(item => {
                    item.upgradeItems = item.upgradeItems?.filter(c => c.id !== incompatibilityId);
                });

                setCaterings(caterings.filter(c => c.id !== incompatibilityId))
            });
    
            incompatibilities.accommodations.map(incompatibilityId => {
                accommodations.map(item => {
                    item.upgradeItems = item.upgradeItems?.filter(c => c.id !== incompatibilityId || c.earlyArrival === true);
                });

                setAccommodations(accommodations.filter(c => c.id !== incompatibilityId || c.earlyArrival === true))
            });
    
            incompatibilities.activities.map(incompatibilityId => {
                activities.map(item => {
                    item.upgradeItems = item.upgradeItems?.filter(c => c.id !== incompatibilityId);
                });

                setActivities(activities.filter(c => c.id !== incompatibilityId))
            });
        }
        
        setNewIncompatibilities(false);
    }, [newIncompatibilities])

    useEffect(() => {
        if (shouldCopyPreviousDay)
            copyPreviousDay(true)
    }, [loading])

    useEffect(() => {
        async function getSections() {
            if (!booking)
                return
            const { meetingRoomsResponse, cateringsResponse, accommodationsResponse, activitiesResponse } = await fetchSectionsData(booking, formatDate(date))
            if (!meetingRoomsResponse || !cateringsResponse || !accommodationsResponse || !activitiesResponse) {
                return alert('Servise unavailable, please try again later')
            }
            setMeetingRoomsAll(meetingRoomsResponse)
            setCateringsAll(cateringsResponse.items)
            setAccommodationsAll(accommodationsResponse.items)
            setActivitiesAll(activitiesResponse.items)
            setLoading(false)
        }
        getSections()
        const timeslot = booking?.timings[day - 1]
        const { meetingRooms, caterings, accommodations, activities } = timeslot?.details || {} 

        // Note: step = day + 1
        if (previousStep < day + 1 && // not comming backwards e.g. from Quoute backwards to the last day
            day > 1 && // not the first day
            !(meetingRooms?.length || caterings?.length || accommodations?.length || activities?.length))
            {
            setShowCopyDay(true)
        }
    }, [booking])

    useEffect(() => {
        if (selectionChangeItems) {
            selectionChangeItems.map(selectionChangeItem => {
                setRequiredItems(selectionChangeItem.item, selectionChangeItem.exist)
            })
        }
    }, [selectionChangeItems])

    const setSeatingLayout = (layout, id: number) => {
        if (!meetingRooms?.length)
            return
        const meetingRoom = meetingRooms.find(mr => mr.id === id)
        if (meetingRoom)
            meetingRoom.seatingLayout = layout
        setMeetingRooms([...meetingRooms])
    }


    // TODO: pushti ja ovde setBookingDetails, odvoi gi goBack() nadvor od nego
    // function submitDay() {
    //     setBookingDetails({ meetingRooms, caterings, accommodations, activities }, formatDate(date))
    // }

    function next(event, nextStep?: number) {
        setError('')
        let currentStep = nextStep !== undefined ? nextStep : (tabInternalStep + 1)

        const error = validateNext(meetingRooms, tabInternalStep)

        if (error) {
            event.preventDefault()
            event.stopPropagation()
            setError(error)
            return
        }
        if (currentStep === 4) {
            if (!getQuoteEnabled) {
                return
            }
            currentStep = 0
            //submitDay()
            // NOTE: step 1 - start, step2 = day1, step3 = day2.. meaning step = day+1
            onStepSelect(day+2) 
        }
        setTabInternalStep(currentStep)
        toggleTab(currentStep)
    }

    function earlyArrivalNoAnswer() {
        setAccommodations(accommodations?.filter(ac => !ac.earlyArrival))
    }

    function additionalRoomsNoAnswer() {
        setMeetingRooms(meetingRooms?.filter(ac => ac.type !== 'additional'))
    }

    function back() {
        let currentStep = (tabInternalStep - 1)
        if (currentStep < 0) {
           // setBookingDetails({ meetingRooms, caterings, accommodations, activities }, formatDate(date), true)
            // NOTE: step 1 - start, step2 = day1, step3 = day2.. meaning step = day+1
           onStepSelect(day)
        }
        setTabInternalStep(currentStep)
        toggleTab(currentStep)
    }

    function toggleItemInList(item: any, items: any[], predicate: (value: any, index: number, obj: any[]) => unknown) {
        item = { ...item, upgradeItems: [] }

        let newItems = items
        const exist = newItems.findIndex(predicate)
        if (exist > -1)
            newItems.splice(exist, 1);
        else {
            newItems.push(item)
        }
        return newItems
    }

    const onSetMeetingRoom = (meetingRoom: MeetingRoom) => {
        let selectionChangeItems = new Array<SelectionChangeItem>();
        const exist = meetingRooms.find(c => c.id === meetingRoom.id);
        let existingMainMeetingRoom;
        if (!exist) {
            if (meetingRoom.type !== "additional") {
                const mainRoomSelectedIndex = meetingRooms.findIndex(mr => mr.meetingRoomType !== "additional")
                if (mainRoomSelectedIndex >= 0) {
                    existingMainMeetingRoom = meetingRooms.splice(mainRoomSelectedIndex, 1);
                }
            }
        }
        
        let newMeetingRooms = toggleItemInList(meetingRoom, meetingRooms, c => c.id === meetingRoom.id);
        let mainMeetingRoom = newMeetingRooms.filter(u => u.type === "main");
        setMeetingRooms(mainMeetingRoom.length === 0 ? [] : [...newMeetingRooms]);
        if (meetingRoom.type === "main") {
            if (existingMainMeetingRoom && existingMainMeetingRoom.length > 0) {
                selectionChangeItems.push({item: existingMainMeetingRoom[0], exist: true});
            }
            
            selectionChangeItems.push({item: meetingRoom, exist: !!exist});
            setSelectionChangeItems(selectionChangeItems);
        }
    }

    const setMeetingRoomUpgrades = (upgrade, meetingRoomId: number) => {
        setMeetingRooms([...meetingRooms.map((meetingRoom) => addUpgrade(meetingRoom, upgrade, meetingRoomId, u => u.id === upgrade.id))])
    }

    const onSetCaterings = (catering: Catering) => {
        let selectionChangeItems = new Array<SelectionChangeItem>();
        const exist = caterings.find(c => c.id === catering.id)
        let newCaterings = toggleItemInList(catering, caterings, c => c.id === catering.id)
        if (catering.cateringType === 'conferencePackage') {
            if (exist && exist.id === catering.id) {
                newCaterings = []
            }
            else {
                newCaterings = [catering]
                caterings.filter(c => c.id !== catering.id).forEach((deselectedCatering) => {
                    selectionChangeItems.push({item: deselectedCatering, exist: true})
                });
            }
        }  

        selectionChangeItems.push({item: catering, exist: !!exist})
        setCaterings([...newCaterings])
        setSelectionChangeItems(selectionChangeItems)
    }

    const onSetCateringsUpgrades = (upgrade: Catering, cateringId: number) => {
        setCaterings([...caterings.map((catering) => addUpgrade(catering, upgrade, cateringId, u => u.id === upgrade.id))])
    }

    const onSetActivities = (activity: Activity) => {
        const exist = activities.find(c => c.id === activity.id)
        let newActivities = toggleItemInList(activity, activities, c => c.id === activity.id)
        setActivities([...newActivities])
        setSelectionChangeItems([{item: activity, exist: !!exist}])
    }

    const onSetActivityUpgrades = (upgrade: Activity, activityId: number) => {
        setActivities([...activities.map((activity) => addUpgrade(activity, upgrade, activityId, u => u.id === upgrade.id))])
    }

    const onSetAccommodations = async (accommodation: Accommodation) => {
        const exist = accommodations.find(c => c.id === accommodation.id && c.earlyArrival === accommodation.earlyArrival)
        let newAccomodations = toggleItemInList(accommodation, accommodations, c => c.id === accommodation.id && c.earlyArrival === accommodation.earlyArrival)
        setAccommodations([...newAccomodations])
        if (!accommodation.earlyArrival) {
            setSelectionChangeItems([{item: accommodation, exist: !!exist}])
        } 
    }

    const onSetAccommodationUpgrades = (upgrade: Accommodation, accommodationId: number) => {
        setAccommodations([...accommodations.map((accomodation) => addUpgrade(accomodation, upgrade, accommodationId, u => u.id === upgrade.id && u.earlyArrival === upgrade.earlyArrival))])
    }

    const setRequiredItems = async (item, exist: boolean) => {
        const selected = { meetingRooms, caterings, accommodations, activities };
        const allItems = {
            meetingRooms: meetingRoomsAll?.items || [],
            caterings: cateringsAll,
            accommodations: accommodationsAll,
            activities: activitiesAll
        };

        let newSelected;
        if (exist) {
            newSelected = await removeRequiredItems(item, selected, allItems);
        } else {
            newSelected = await addRequiredItems(item, selected, allItems);
        }

        if (newSelected) {
            setMeetingRooms([...newSelected.meetingRooms]);
            setCaterings([...newSelected.caterings]);
            setAccommodations([...newSelected.accommodations]);
            setActivities([...newSelected.activities]);
        }
    }

    const updateItemInput = (value: number, item: ItemType, parentItem: ItemType, type: string) => {
        switch (type) {
            case 'meetingrooms':
                return setMeetingRooms(updateInputValue(meetingRooms, item, parentItem, value))
            case 'caterings':
                return setCaterings(updateInputValue(caterings, item, parentItem, value))
            case 'accommodations':
                return setAccommodations(updateInputValue(accommodations, item, parentItem, value))
            case 'activities':
                return setActivities(updateInputValue(activities, item, parentItem, value))
        }
    }

    function getItemForDay(allItems, selectedItems, parentItem?) {
        const attendeesNo = timeslot?.attendeesNo
        const items: any = []
        for (let selectedItem of selectedItems) {
            const item = allItems.find(it => it.id === selectedItem.id);
            if (item) {
                if (selectedItem.inputValue) {
                    item.inputValue = getRecommendedValue(item, attendeesNo || 1, parentItem)
                }

                if (selectedItem.attendeesNo)
                    item.attendeesNo = selectedItem.attendeesNo;

                if (selectedItem.upgradeItems)
                    item.upgradeItems = getItemForDay(allItems, selectedItem.upgradeItems, item);

                if (selectedItem.type)
                    item.type = selectedItem.type;

                if (selectedItem.seatingLayout)
                    item.seatingLayout = selectedItem.seatingLayout;

                items.push(item);
            }
        }

        return items;
    }

    const copyPreviousDay = async (shouldCopyPreviousDay: boolean) => {
        if (shouldCopyPreviousDay) {
            if (loading) {
                setShouldCopyPreviousDay(true);
                return;
            }

            const details = booking?.timings[day - 2]?.details;
            
            let selectedMeetingRooms = new Array<any>();
            let selectedMainMeetingRoom = details?.meetingRooms.filter(u => u.type === "main")[0];
            if (selectedMainMeetingRoom && meetingRoomsAll?.items.find(u => (u.meetingRoomType === "main" || u.meetingRoomType === "all") && u.id === selectedMainMeetingRoom.id)) {
                selectedMeetingRooms = [selectedMainMeetingRoom].concat(details?.meetingRooms.filter(u => u.type === "additional") || []);
            }
            
            const itemForDayMeetingRooms = getItemForDay(meetingRoomsAll?.items, selectedMeetingRooms);
            const itemForDayCaterings = getItemForDay(cateringsAll, details?.caterings || []);
            const itemForDayAccommodations = getItemForDay(accommodationsAll, details?.accommodations.filter(u => u.earlyArrival !== true) || []);
            const itemForDayActivities = getItemForDay(activitiesAll, details?.activities || []);

            setMeetingRooms(itemForDayMeetingRooms);
            setCaterings(itemForDayCaterings);
            setAccommodations(itemForDayAccommodations);
            setActivities(itemForDayActivities);

            let selectionChangeItems = new Array<SelectionChangeItem>();
            selectionChangeItems = selectionChangeItems.concat(getSelectionChangeItems(itemForDayMeetingRooms, false));
            selectionChangeItems = selectionChangeItems.concat(getSelectionChangeItems(itemForDayCaterings, false));
            selectionChangeItems = selectionChangeItems.concat(getSelectionChangeItems(itemForDayAccommodations, false));
            selectionChangeItems = selectionChangeItems.concat(getSelectionChangeItems(itemForDayActivities, false));

            setSelectionChangeItems(selectionChangeItems);
            setTabInternalStep(0)
            setShouldCopyPreviousDay(false);
        }
    }

    const pricesProps = { booking, priceSettings, getQuoteEnabled, currentDaySelection: { meetingRooms, caterings, accommodations, activities }, currentDate: formatDate(date), getQuote: () => onStepSelect(7), t }
    return (
        <div className="container detailsStep">
            {error && <div className="alert alert-danger">{error}</div>}
            {showCopyDay && <CopyFromPreviousDayModal copyPreviousDay={copyPreviousDay} t={t} />}

            <ul className="nav nav-tabs menu" id="menu" role="tablist">
                {menus.map((menu, index) => <li className="nav-item" role="presentation" key={menu}>
                    <a className={`nav-link ${index === tabInternalStep ? 'active' : ''}`} onClick={(e) => next(e, index)}
                        data-toggle="tab" role="tab" aria-selected="true"
                        id={menu + '-tab'} aria-controls={menu} href={'#' + menu} >{t('menu.' + menu)}</a>
                </li>)}
            </ul>
            {loading &&
                <div className="card-body row loading">
                    <Loader type="ThreeDots" color="#000000" height={40} width={40} />
                </div>
            }
            {!loading && 
                <div className="tab-content" id="menuContent">
                    <div className={`tab-pane fade  ${tabInternalStep === 0 ? 'show active' : ''}`} id="meetingRooms" aria-labelledby="meetingRooms-tab" role="tabpanel">
                        <MeetingRooms
                            meetingRoomsAll={meetingRoomsAll}
                            meetingRooms={meetingRooms}
                            incompatibilities={incompatibilities.meetingRooms}
                            loading={loading}
                            updateItemInput={updateItemInput}
                            setMeetingRoom={onSetMeetingRoom}
                            setSeatingLayout={setSeatingLayout}
                            setUpgrades={setMeetingRoomUpgrades}
                            timeslot={timeslot}
                            onNext={next}
                            onAdditionalRoomsNoAnswer={additionalRoomsNoAnswer}
                            t={t} />
                    </div>
                    <div className={`tab-pane fade ${tabInternalStep === 1 ? 'show active' : ''}`} id="caterings" aria-labelledby="caterings-tab" role="tabpanel" >
                        <Caterings
                            cateringsAll={cateringsAll}
                            caterings={caterings}
                            incompatibilities={incompatibilities.caterings}
                            timeslot={timeslot}
                            loading={loading}
                            setCatering={onSetCaterings}
                            setUpgrades={onSetCateringsUpgrades}
                            updateItemInput={updateItemInput}
                            resetCaterings={(type) => { 
                                setCateringType(type); 
                                caterings.filter(c => c.cateringType !== type).map(cateringItem => {
                                    onSetCaterings(cateringItem);
                                })
                            }}
                            t={t} />
                    </div>
                    <div className={`tab-pane fade ${tabInternalStep === 2 ? 'show active' : ''}`} id="accommodations" aria-labelledby="accommodations-tab" >
                        <Accomodations
                            accommodationsAll={accommodationsAll}
                            accommodations={accommodations}
                            incompatibilities={incompatibilities.accommodations}
                            timeslot={timeslot}
                            date={formatDate(date)}
                            loading={loading}
                            onNext={next}
                            onEarlyArrivalNoAnswer={earlyArrivalNoAnswer}
                            setAccomodations={onSetAccommodations}
                            setUpgrades={onSetAccommodationUpgrades}
                            updateItemInput={updateItemInput}
                            earlyArrivalsEnabled={day === 1}
                            t={t} />
                    </div>
                    <div className={`tab-pane fade ${tabInternalStep === 3 ? 'show active' : ''}`} id="activities" aria-labelledby="activities-tab" >
                        <Activities
                            activitiesAll={activitiesAll}
                            activities={activities}
                            incompatibilities={incompatibilities.activities}
                            timeslot={timeslot}
                            loading={loading}
                            setActivities={onSetActivities}
                            setUpgrades={onSetActivityUpgrades}
                            updateItemInput={updateItemInput}
                            t={t} />
                    </div>
                </div>
            }

            <div className="row actions p0">
                <div className="col-sm-12 p0">
                    {error && <div className="alert alert-danger">{error}</div>}
                    <button type="button" className="btn btn-primary float-left" onClick={back}>{t('back')}</button>
                    <PriceComponent {...pricesProps} />
                    <button type="button" className="btn btn-primary float-right" disabled={tabInternalStep == 3 && !getQuoteEnabled} onClick={e => next(e)}>{t('next')}</button>
                </div>
            </div>
        </div>
    );
})
