import React, { useEffect, useRef, useState } from 'react';
import { calculatePrice, getPricesForDysplay } from '../../../models/price';
import { Details } from '../../../models/requiredItems';
import { Booking } from '../../types/booking';
import { Price } from '../../types/settings';
import { formatPriceString } from '../../../models/utils';
import './Price.scss';

type Props = {
    booking: Booking | undefined
    currentDaySelection?: Details
    currentDate?: string
    priceSettings: Price
    getQuoteEnabled: boolean
    getQuote: () => void
    t: any
}

export default function PriceComponent({ booking, currentDaySelection, priceSettings, currentDate, getQuoteEnabled, getQuote, t }: Props) {
    const [expanded, setExpanded] = useState(false)
    const [positionFixed, setPositionFixed] = useState(false)
    const { totalPrice, pricePerGuest, totalPriceIncVAT, pricePerGuestIncVAT } = getPricesForDysplay(booking, currentDaySelection, currentDate)
    const priceRef: any = useRef(null)

    const setPricePosition = () => {
        if (!priceRef)
            return
        const position = priceRef.current?.getBoundingClientRect()?.top

        if (window.innerHeight < position) {
            setPositionFixed(true)
        } else if (window.innerHeight > position) {
            setPositionFixed(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', setPricePosition)
        $(window).click(() => setTimeout(() => setPricePosition(), 200));
        return function cleanupListeners() {
            window.removeEventListener('scroll', setPricePosition)
        }
    }, [])

    const currency = priceSettings.currency.code;
    const netPrices = priceSettings.priceDisplay === 'net';
    
    return (
        <div>
            <div className={`price ${expanded ? 'expanded' : ''} ${positionFixed ? 'fixed' : ''}`} onClick={() => setExpanded(!expanded)}>
                {currency} {formatPriceString((netPrices ? pricePerGuest : pricePerGuestIncVAT))} / {t('guest')} {expanded ? '↓' : '↑'}
                {expanded &&
                    <div>
                        <div className="total">{currency} {formatPriceString(netPrices ? totalPrice : totalPriceIncVAT)} {t('total')}</div>
                        <div>{currency} {formatPriceString(pricePerGuestIncVAT)} / {t('guest')} {t('incVAT')}</div>
                        <div>{currency} {formatPriceString(totalPriceIncVAT)} {t('total')} {t('incVAT')}</div>
                        <button type="button" className="btn btn-primary " onClick={e => getQuote()} disabled={!getQuoteEnabled}>{t('getQuote')}</button>
                    </div>}
            </div>
            <div ref={priceRef}>
            </div>
        </div>
    )
}
