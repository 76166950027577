import { MeetingRoom } from '../components/types/meetingRooms'
import { ItemType } from './requiredItems'

export function validateNext(meetingRooms: MeetingRoom[], step: number) {
    let missingSeatingLayout = false
    for (let meetingRoom of meetingRooms) {
        if (!meetingRoom.seatingLayout)
            missingSeatingLayout = true
    }
    switch (step) {
        case 0:
        case 1:
        case 2:
        case 3:
        default: return undefined
    }
}

export function toggleTab(currentStep: number) {
    switch (currentStep) {
        case 0:
            $('#meetingRooms').tab('show')
            return
        case 1:
            $('#caterings').tab('show')
            return
        case 2:
            $('#accommodations').tab('show')
            return
        case 3:
            $('#activities').tab('show')
            return
    }
}

export function updateInputValue(items, item: ItemType, parentItem: ItemType, value: number) {
    // TODO: just temporary, this can't scale, we need seperate state collections for early arrival rooms and additional meeting rooms
    for (let it of items.filter(u => u.id === (parentItem?.id || u.id))) {
        if (item.sectionTypeOption === 'upgrade') {
            for (let itUpgrade of it.upgradeItems) {
                if (itUpgrade.id === item.id 
                        && ((itUpgrade.earlyArrival || false) === (item.earlyArrival || false))
                        && ((itUpgrade.type || '') === (item.type || ''))
                ) {
                    itUpgrade.inputValue = value
                }
            }
        } else {
            if (it.id === item.id 
                    && ((it.earlyArrival || false) === (item.earlyArrival || false))
                    && ((it.type || '') === (item.type || ''))
            ) {
                it.inputValue = value
            }
        }
    }

    return [...items]
}

export function addUpgrade(item, upgrade: any, id: number | undefined, predicate: (value: any, index: number, obj: any[]) => unknown) {
    // TODO: just temporary, this can't scale, we need seperate state collections for early arrival rooms and additional meeting rooms
    if (item.id === id 
            && ((item.earlyArrival || false) === (upgrade.earlyArrival || false)) // accommodations diff.
            && ((item.type || '') === (upgrade.type || '')) // meeting rooms diff.
    ) {
        if (!item.upgradeItems)
            item.upgradeItems = []
        const exist = item.upgradeItems.find(predicate)
        if (exist) {
            item.upgradeItems = item.upgradeItems.filter(u => u.id !== upgrade.id)
        } else{
            item.upgradeItems.push(upgrade)
        }
    }
    return item
}
