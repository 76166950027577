import { Details, ItemType, itemTypes } from './requiredItems';

export function returnIncompatibitities(selected: Details) {
    const incompatibilities = {
        meetingRooms: [],
        caterings: [],
        accommodations: [],
        activities: []
    }
    for (let type of itemTypes) {
        for (let selectedItem of selected[type]) {
            getIncompatibleItems(selectedItem, incompatibilities)
            if (selectedItem.upgradeItems) {
                for (let selectedUpgradeItem of selectedItem.upgradeItems) {
                    getIncompatibleItems(selectedUpgradeItem, incompatibilities)
                }
            }
        }
    }
    return incompatibilities
}


export function getIncompatibleItems(selectedItem: ItemType, incompatibilities) {
    if (!selectedItem.incompatibilities) return
    for (let type of itemTypes) {
        for (let incompatibleItem of selectedItem.incompatibilities[type]) {
            incompatibilities[type].push(incompatibleItem.id)
        }
    }
}