import { Settings } from '../components/types/settings'

export function applyTheming(design: Settings['design']) {
    document.documentElement.style.setProperty('--c-accent', design.primaryColorHex)
    document.documentElement.style.setProperty('--c-secondary', design.secondaryColorHex)
    document.documentElement.style.setProperty('--c-copyText', design.copyTextColorHex)
    document.documentElement.style.setProperty('--c-button', design.buttonTextColorHex)
    document.documentElement.style.setProperty('--c-bg-button', design.buttonBackgroundColorHex)
    document.documentElement.style.setProperty('--bg-body', design.backgroundColorHex)
}
