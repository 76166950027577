import React, {useState} from 'react';
import { Settings } from './components/types/settings';

const SettingsContext = React.createContext<any>(null);

export function SettingsProvider(props) {
    const [settings, setSettings] = useState<Settings>();
    const value = [settings, setSettings];
    return <SettingsContext.Provider value={value} {...props} />
}

export function useSettings() {
    const context = React.useContext(SettingsContext);
    if (!context) {
      throw new Error('useSettings must be used within a SettingsProvider');
    }

    return context;
}