import axios from 'axios'
import { Booking } from '../components/types/booking'
import { Inquery, InquiryInfo } from '../components/types/inquery'
import { Country, FormField, Settings } from '../components/types/settings'
import { getLang, getTotalMinutesFromTime, ItemType, parseQuery } from './utils'

const serverUrl = process.env.REACT_APP_SERVER_URL
const imageUrl = process.env.REACT_APP_IMAGE_SERVER_URL
const queryparams: any = parseQuery(window.location.search)
let userId = queryparams.userId
// if (window.location.origin == 'http://localhost:3000' && !userId)
//     userId = 'ccecf0fe-d789-423f-9a06-c7475888a669'//'24f9e16e-7675-4b2c-94f7-871ef4ba9a22'

let language = getLang()
let configuratorId = ''

export function prepareTimingsForFetch(booking: Booking) {
    const timings: any[] = []
    for (let i in booking.timings) {
        const timing = booking.timings[i]
        timings.push({
            date: timing.date,
            attendeesNo: timing.attendeesNo,
            startTime: getTotalMinutesFromTime(timing.startTime, timing.isPlusOneDay),
            endTime: getTotalMinutesFromTime(timing.endTime, timing.isPlusOneDay),
            meetingTimeSlotId: timing.meetingTimeSlotId
        })
    }
    return timings
}

export async function createInquery(inquery: Inquery) {
    const result = await fetchDataWithPost('inquiries/quote', inquery)
    return result
}

export async function fetchSectionsData(booking: Booking, date: string) {
    const bookingRequestObject = { ...booking, timings: prepareTimingsForFetch(booking), date }
    const [meetingRoomsResponse, cateringsResponse, accommodationsResponse, activitiesResponse] = await Promise.all([
        fetchDataWithPost('meetingrooms', bookingRequestObject),
        fetchDataWithPost('caterings', bookingRequestObject),
        fetchDataWithPost('accommodations', bookingRequestObject),
        fetchDataWithPost('activities', bookingRequestObject)
    ])

    return { meetingRoomsResponse, cateringsResponse, accommodationsResponse, activitiesResponse }
}

export async function fetchVenueUnavailability(startDate, endDate): Promise<{ dates: string[] } | undefined> {
    return fetchData(`settings/venue-unavailability?startDate=${formatDate(startDate)}&endDate=${formatDate(endDate)}`)
}

export async function fetchSettings(): Promise<Settings | undefined> {
    const res = await fetchData('settings')
    configuratorId = res?.configuratorId
    return res
}

export async function fetchCountries(): Promise<Country[]> {
    return await fetchData(`countries`)
}

export async function fetchItem(type: string, id: number): Promise<ItemType | undefined> {
    return await fetchData(`${type}/${id}`)
}

export async function fetchForm(): Promise<FormField[]> {
    return await fetchData('requestform')
}

export async function fetchData(path: string): Promise<any> {
    try {
        const res = await axios.get(serverUrl + path, {
            headers: {
                'X-User': userId,
                'X-Language': language,
                'X-Configurator': configuratorId
            }
        })
        return res.data
    } catch (err) {
        console.log('Error in fetch', err)
    }
    return undefined
}

export async function fetchDataWithHttpStatusCode(path: string): Promise<any> {
    try {
        const res = await axios.get(serverUrl + path, {
            validateStatus: status => status < 500,
            headers: {
                'X-User': userId,
                'X-Language': language,
                'X-Configurator': configuratorId
            }
        })
        
        return res.data

    } catch (error) {
        
        console.log('Error in fetch', error)

        return undefined;
    }
}

export async function fetchDataWithPost(path: string, body?): Promise<any> {
    try {
        const res = await axios.post(serverUrl + path, body, {
            headers: {
                'X-User': userId,
                'X-Language': language,
                'X-Configurator': configuratorId
            }
        })
        return res.data
    } catch (err) {
        return { error: err }
        console.log('Error in fetch', err)
    }
    return undefined
}


export async function fetchDataWithPutWithHttpStatusCode(path: string, body?): Promise<any> {
    try {
        const res = await axios.put(serverUrl + path, body, {
            headers: {
                'X-User': userId,
                'X-Language': language,
                'X-Configurator': configuratorId
            }
        })
        return res.data
    } catch (err) {
        console.log('Error in fetch', err)
        
        return err;
    }
}


export function formatDate(date: Date | undefined) {
    if (!date) return ''
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`
}

export function formatUtcDateTime(date: Date | undefined) {
    if (!date) return ''
    return `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(date.getUTCDate())} ${pad(date.getUTCHours())}:${pad(date.getUTCMinutes())}`
}

export const pad = (x: number) => (x < 10 ? `0${x}` : x)


export function getImageUrl(container, imageName, size) {
    // container  = meetingrooms | caterings | accommodations | activities
    // size = small | medium | large | xlarge
    return `${imageUrl}image/${userId}/${container}/${imageName}/${size}.webp`
}

export async function fetchInquiryAcceptedInfo(v:string, refId:string): Promise<InquiryInfo | any> {
    return await fetchDataWithHttpStatusCode(`inquiries/?v=${v}&refId=${refId}`)
}

export async function acceptInquiryOffer(v:string,refId:string):Promise<any> {
  return await fetchDataWithPutWithHttpStatusCode(`inquiries/accept?v=${v}&refId=${refId}`);
}