import './Timeslot.scss'
import { Range } from 'rc-slider';
import Input from '../utils/Input';
import 'rc-slider/assets/index.css';
import { TimeSlot } from '../types/settings';
import React, { useEffect, useState } from 'react';
import { getDateString, getRangeIndex, getTimeFromRangeIndex } from '../../models/utils';

type Props = {
    date: string,
    timings,
    setTimings,
    timeSlots,
    timeSlotAdjustments,
    t: any
    minAttendees: number | undefined
    maxAttendees: number | undefined
    defaultAttendies: number | undefined
    datesState: string[]
}
export function Timeslot({ date, timings, setTimings, timeSlots, timeSlotAdjustments, minAttendees, maxAttendees, defaultAttendies, datesState, t }: Props) {
    const [errorAttendees, setErrorAttendees] = useState<boolean>()

    const timeslot: TimeSlot = timings[date]
    const { meetingTimeSlotId, startTime, endTime, isPlusOneDay, attendeesNo } = timeslot || {}
    const [attendeesState, setAttendees] = useState<number | undefined>(attendeesNo)

    const startIndex = getRangeIndex(startTime, isPlusOneDay, false)
    const endIndex = getRangeIndex(endTime, isPlusOneDay, true)
    const originalTimelot = timeSlots?.find(item => item.id === meetingTimeSlotId)
    const originalStartIndex = getRangeIndex(originalTimelot?.startTime, isPlusOneDay, false)
    const originalEndIndex = getRangeIndex(originalTimelot?.endTime, isPlusOneDay, true)
    const { maxExtendHours, maxStartTimeShiftHours, maxEndTimeShiftHours } = timeSlotAdjustments || {}
    const maxStartShift = Math.min(((maxStartTimeShiftHours || 24) * 2), getMaxExtendedHoursTicks() - (endIndex - originalEndIndex))
    const maxEndShift = Math.min(((maxEndTimeShiftHours || 24) * 2), getMaxExtendedHoursTicks() - (originalStartIndex - startIndex))

    useEffect(() => {
        if (attendeesState !== timeslot?.attendeesNo)
            setAttendees(timeslot?.attendeesNo)
    }, [timeslot?.attendeesNo])

    function validateAttendies(value: number) {
        if (!value) {
            value = 0;
        }

        return !(value < (minAttendees || 0) || value > (maxAttendees || 9999999))
    }

    function getMaxExtendedHoursTicks() {
        return ((maxExtendHours || 24) * 2)
    }

    function setTimeslot(timeslot) {
        timings[date] = { ...timeslot }
        setTimings({ ...timings })
    }

    return (<div className="row col-sm-12 timeslot">
        <h1 className="col-sm-12"> {getDateString(new Date(date))}</h1>

        <Input
            type="select"
            className={meetingTimeSlotId ? '' : 'invalid'}
            label={t('timing')}
            value={meetingTimeSlotId}
            onChange={(event) => {
                const { target: { value } } = event
                const timeslot = timeSlots?.find(item => item.id === parseInt(value))
                if (timeslot) {
                    timings[date] = {
                        ...timeslot,
                        meetingTimeSlotId: timeslot.id,
                        date,
                        attendeesNo: defaultAttendies
                    }
                    setTimings({ ...timings })
                }
            }}
        >
            <option className="error-message">{t('timing')}</option>
            {timeSlots?.map(slot => <option key={slot.id} value={slot.id}>{slot.name}</option>)}
        </Input>

        {meetingTimeSlotId &&
            <Input
                label={t('participants') + ` (${minAttendees}-${maxAttendees}):`}
                type="number" min={minAttendees} max={maxAttendees} name="attendiees"
                value={attendeesState} 
                onChange={(event) => {
                    let { target: { value } } = event;
                    value = parseInt(value);
                    setErrorAttendees(!validateAttendies(value || 0));
                    setAttendees(value || "");
                    if (!defaultAttendies) {
                        defaultAttendies = value;
                    }

                    if (value) {
                        timeslot.attendeesNo = value;
                        setTimeslot(timeslot);
                    }
                }}
                onBlur={() => {
                    if (datesState?.length) {
                        for (let i in datesState) {
                            if (datesState[i]) {
                                const timeslot = timings[datesState[i]]
                                if (timeslot && !timeslot.attendeesNo) {
                                    timeslot.attendeesNo = attendeesState
                                }
                            }
                        }
                        setTimings({ ...timings })
                    }
                }}
                error={errorAttendees ? t('errorMinMax', { min: minAttendees, max: maxAttendees }) : ''}
            />}

        <div className="form-group col-sm-12 timeslot_range">
            <label>{startTime}</label>
            <label className="float-right">{endTime} {isPlusOneDay && endIndex > 24 && t('nextDay')}</label>

            {meetingTimeSlotId && <Range min={0} max={48} step={1} value={[startIndex, endIndex]} onChange={([start, end]) => {
                if ((originalStartIndex - start) > maxStartShift) {
                    start = originalStartIndex - maxStartShift
                }

                if ((end - originalEndIndex) > maxEndShift) {
                    end = originalEndIndex + maxEndShift
                }

                if (start === end) {
                    if (start > startIndex)
                        start = start - 1
                    else
                        end = end + 1
                }

                timings[date] = {
                    ...timeslot,
                    startTime: getTimeFromRangeIndex(start, isPlusOneDay, originalTimelot.startTime),
                    endTime: getTimeFromRangeIndex(end, isPlusOneDay, originalTimelot.endTime)
                }
                setTimings({ ...timings })
            }} />}
        </div>
    </div>)
}
