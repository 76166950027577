import Input from '../utils/Input';
import { useSettings } from '../../SettingsContext';
import { Timeslot } from './Timeslot';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Booking } from '../types/booking';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { fetchVenueUnavailability, formatDate } from '../../models/api';
import { getLang, monthDiff, getNextDays, getTimings } from '../../models/utils';
import de from 'date-fns/locale/de';

import 'react-datepicker/dist/react-datepicker.css';
import './FirstStep.scss';

let language = getLang()

if (language === 'de')
    registerLocale('de', de)

type Props = {
    booking: Booking | undefined
    t: any
    onNext: (booking: Booking) => void
}

const FirstStep = forwardRef(({ booking, t, onNext }: Props, ref) => {
    const [settings] = useSettings();
    const { timeSlots, timeSlotAdjustments, minAttendees, maxAttendees, maxDurationDays } = settings || {}

    const [startDate, setStartDate] = useState(booking?.startDate)
    const [duration, setDuration] = useState<number>(booking?.durationDays || 1);
    const { dates } = getNextDays(startDate, settings?.maxDurationDays);
    const [timings, setTimings] = useState(getTimings(booking?.timings, booking?.date, settings?.maxDurationDays))
    const [coupon, setCoupon] = useState<string>(booking?.couponCode || '')

    const [datesState, setDates] = useState<string[]>(dates.map(date => formatDate(date)))
    const [datePickerMonth, setDatePickerMonth] = useState(startDate ? new Date(startDate) : null)
    const [lastFetchedVenueUnavailabilityStartDate, setLastFetchedVenueUnavailabilityStartDate] = useState<Date>(startDate ? new Date(startDate) : new Date())
    const [lastFetchedVenueUnavailabilityEndDate, setLastFetchedVenueUnavailabilityEndDate] = useState<Date>(startDate ? new Date(startDate) : new Date())
    const [venueUnavailability, setVenueUnavailability] = useState<Date[]>([])
   

    let attendiesValid = true
    for (let time of Object.keys(timings)) {
        if (!timings[time].attendeesNo)
            attendiesValid = false
    }
    const validateAttendiesForTimeslots = () => {
        let valid = true

        for (let date of datesState) {
            const timing = timings[date]
            if (timing && !validateAttendies(timing.attendeesNo))
                valid = false
        }
        return valid
    }

    function validateAttendies(value: number) {
        return !(value < (minAttendees || 0) || value > (maxAttendees || 9999999))
    }

    const timingsValid = Object.keys(timings).length === duration && attendiesValid
    const nextEnabled = dates.length > 0 && duration && timingsValid && validateAttendiesForTimeslots()

    useImperativeHandle(ref, () => ({
        onNextRequested() {
            if (!nextEnabled)
                return false

            next()
            return true
        }
    }));

    useEffect(() => {
        async function getVenueUnavailability(startDate?) {
            if (!startDate) {
                startDate = new Date()
            }
            
            if (monthDiff(startDate, lastFetchedVenueUnavailabilityEndDate) < 3 || monthDiff(lastFetchedVenueUnavailabilityStartDate, startDate) < 3) {
                
                let requestStartDate = new Date(startDate);
                let requestEndDate = new Date(startDate);
                requestStartDate.setMonth(startDate.getMonth() - 6);
                requestEndDate.setMonth(startDate.getMonth() + 6);

                setLastFetchedVenueUnavailabilityStartDate(requestStartDate);
                setLastFetchedVenueUnavailabilityEndDate(requestEndDate);

                const venueUnavailabilityResponse = await fetchVenueUnavailability(requestStartDate, requestEndDate)
                
                if (venueUnavailabilityResponse) {
                    setVenueUnavailability(venueUnavailabilityResponse.dates.map(date => new Date(date)))
                } 
            }
        }

        getVenueUnavailability(datePickerMonth)
    }, [datePickerMonth])

    useEffect(() => {
        if (Object.keys(timings).length !== duration) {
            const newDates: any = dates.map(date => formatDate(date))
            for (let i = 0; i < newDates.length; i++) {
                if (i + 1 > duration) {
                    delete timings[newDates[i]]
                }
            }
            setTimings({ ...timings })
        }
    }, [startDate, duration])

    useEffect(() => {
        const maxDays = datesState.reduce((sum, item) => sum + (item ? 1 : 0), 0)
        if (maxDays > 0 && duration > maxDays) {
            setDuration(maxDays)
        }
    }, [datesState])

    useEffect(() => {
        if (startDate) {
            const { dates } = getNextDays(startDate, settings?.maxDurationDays)
            const newDates: any = dates.map(date => formatDate(date))
            for (let day of venueUnavailability) {
                for (let i = 0; i < newDates.length; i++) {
                    if (i && newDates[i] && newDates[i] === formatDate(day) || (i > 0 && !newDates[i - 1])) {
                        newDates[i] = undefined
                    }
                }
            }
            setDates(newDates)
        } else {
            setDates([])
        }

    }, [startDate])

    const handleChange = (event: any) => {
        const { target: { name, value } } = event
        switch (name) {
            case 'duration':
                setDuration(parseInt(value))
                break
            case 'coupon':
                setCoupon(value)
                break
        }
    }

    function next() {
        const timingsArray: any[] = []

        for (let time in timings) {
            const oldTiming = booking?.timings[time]
            const { date, meetingTimeSlotId, startTime, endTime, attendeesNo, isPlusOneDay } = timings[time]
            timingsArray.push({ ...oldTiming, date, meetingTimeSlotId, startTime, endTime, attendeesNo, isPlusOneDay })
        }

        const newBooking = {
            date: datesState[0],
            inquiryDate: formatDate(new Date()),
            startDate: datesState[0],
            durationDays: duration || 1,
            timings: timingsArray,
            couponCode: coupon || undefined,
        }
        onNext({ ...booking, ...newBooking })
    }

    const timeslotProps = { timings, timeSlots, setTimings, timeSlotAdjustments, minAttendees, maxAttendees, t, defaultAttendies: timings[datesState[0]]?.attendeesNo }
    return (
        <div className="container firstStep">
            <div className="row">
                <h1 className="col-sm-12">{t('fistStep.when')}</h1>
                <Input type="custom" label={t('startDate')} value={0}>
                    <DatePicker
                        locale={language}
                        className="date-picker"
                        popperPlacement="bottom-start"
                        popperModifiers={{
                            flip: {
                                behavior: ['bottom-start']
                            },
                            preventOverflow: {
                                enabled: false
                            },
                            hide: {
                                enabled: false
                            }
                        }}
                        minDate={new Date()}
                        selected={startDate ? new Date(startDate) : undefined}
                        excludeDates={venueUnavailability}
                        onMonthChange={(date) => setDatePickerMonth(date)}
                        onChange={async date => {
                            let venueUnavailabilityResult = await fetchVenueUnavailability(date, date)
                            if ((venueUnavailabilityResult?.dates.length || 0) > 0) {
                                return;
                            }

                            setTimings({})
                            setStartDate(date)
                        }}
                        onChangeRaw={(e) => e.preventDefault()}
                    />
                </Input>

                <Input className="duration" type="select" name="duration" label={t('duration')} value={duration} onChange={handleChange}>
                    {new Array(maxDurationDays).fill(1).map((item, index) => {
                        const disabled = !datesState[index]
                        return <option key={index} disabled={disabled} value={index + 1}>{t(`${index + 1} Day`)}</option>
                    })}
                </Input>
            </div>

            {(duration && dates[0]) ? <div className="row">
                {datesState.map((date, index) => {
                    if (index + 1 <= duration)
                        return <Timeslot key={date} date={date} {...timeslotProps} datesState={index == 0 ? datesState : []} />
                })}
            </div> : ''}

            <div className="row">
                <h1 className="col-sm-12">{t('fistStep.coupon')}</h1>
                <Input
                    type="text"
                    name="coupon"
                    label={t('fistStep.coupon.label')}
                    value={coupon}
                    onChange={handleChange}
                />
            </div>

            <div className="row actions p0">
                <div className="col-sm-12 p0">
                    <button type="button" className="btn btn-primary float-right" disabled={!nextEnabled} onClick={next}>{t('next')}</button>
                </div>
            </div>
        </div>
    );
})
export default FirstStep
