import './Modal.scss'
import React, { useEffect, useState } from 'react';
import { fetchItem, getImageUrl } from '../../models/api';
import { MeetingRoom } from '../types/meetingRooms';
import { getLang, ItemType } from '../../models/utils';

const lang = getLang()
type Props = {
    item: ItemType
    t: any
    type: string,
    onClose: () => void
}
export default function Modal({ item, type, onClose, t }: Props) {
    const [completeItem, setCompleteItem] = useState<ItemType>()

    useEffect(() => {
        getForm()
        $(`#card-modal-${item.id}`).on('hidden.bs.modal', (e) => onClose())
    }, [])

    async function getForm() {
        const fullItem = await fetchItem(type, item.id)
        setCompleteItem(fullItem)
    }

    const meetingRoomCaracteristics = () => {
        const mr = item as MeetingRoom
        const maxCapacity = (completeItem as MeetingRoom)?.maxCapacity
        return (
            <div className="col-sm-4">
                <h3 className="col-sm-12 ml0">{t('modal.caracteristics')}</h3>
                <div>{t('modal.size')} {mr.sizeSqm} {t('modal.sqm')} </div>
                {maxCapacity && <div>{t('modal.maxCapacity')} {maxCapacity} {t('modal.pax')} </div>}
            </div>
        )
    }
    const meetingRoomAmenities = () => {
        const amenities = (completeItem as MeetingRoom)?.amenities
        if (!amenities?.length)
            return
        const sections = {}
        for (let amenity of amenities) {
            if (!sections[amenity.section])
                sections[amenity.section] = []
            sections[amenity.section].push(amenity)
        }
        const sectionOrder = ['Catering', 'Health & safety', 'Room characteristics', 'Equipment']
        const sectionOrderDe = ['Catering', 'Gesundheit & Sicherheit', 'Raummerkmale', 'Ausstattung']
        return (<div className="col-sm-8 amenities">
            <h3 className="col-sm-12 ml0">{t('modal.amenities')}</h3>
            {(lang == 'en' ? sectionOrder : sectionOrderDe).map(section => {
                if (!sections[section]) return
                return (<div key={section} className="col-sm-12">
                    <h4>{t(section)}</h4>
                    <div className="amenities">
                        {sections[section].map((item, i) => {
                            const icon = require('./../../resouces/icons/amenities/' + item.iconPath)

                            return <div key={i} className="amenity">
                                <img className="" src={icon} alt={`${i} `} /> <p>{t(item.name)}</p>
                            </div>
                        })}
                    </div>
                </div>)
            })}
        </div>)
    }
    const renderHighlights = (highlights) => {
        if (!highlights?.length)
            return

        return (
            <div className="col-sm-6">
                <h3 className="col-sm-12 ml0">{t('modal.highlights')}</h3>
                <ul className="">
                    {highlights.map((item, i) => {
                        return <li key={i}>{item.description}</li>
                    })}
                </ul>
            </div>
        )
    }

    return (
        <div className="modal" id={`card-modal-${item.id}`} tabIndex={-1} role="dialog">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{item.name}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div id="modal-carousel" className="carousel slide" data-ride="carousel">
                            <ol className="carousel-indicators">
                                {item?.images.map((image, i) => (
                                    <li key={i} data-target="#modal-carousel" data-slide-to="0" className={i === 0 ? 'active' : ''}></li>
                                ))}
                            </ol>
                            <div className="carousel-inner">
                                {item.images.map((image, i) => (
                                    <div className={`carousel-item ${i === 0 ? 'active' : ''}`} key={i}>
                                        <img className="d-block w-100" src={getImageUrl(type, image.name, 'large')} alt={`${i} slide`} />
                                    </div>
                                ))}
                            </div>
                            <a className="carousel-control-prev" href="#modal-carousel" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">{t('previous')}</span>
                            </a>
                            <a className="carousel-control-next" href="#modal-carousel" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">{t('next')}</span>
                            </a>
                        </div>
                        <div className="row details">
                            <p className="card-text col-sm-6" dangerouslySetInnerHTML={{ __html: item.description }} />

                            {renderHighlights(completeItem?.highlights)}
                            {type === 'meetingrooms' && item.sectionTypeOption !== 'upgrade' && meetingRoomCaracteristics()}
                            {type === 'meetingrooms' && item.sectionTypeOption !== 'upgrade' && meetingRoomAmenities()}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={onClose} data-dismiss="modal"> {t('close')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
