import React from 'react';
import ReactDOM from 'react-dom';
import { SettingsProvider } from './SettingsContext'
import App from './components/App';
import './models/i18n'
import './resouces/main.css'
import './resouces/theming.scss'

ReactDOM.render(
  <React.StrictMode>
    <SettingsProvider>
      <App />
    </SettingsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

