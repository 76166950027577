import React, { useEffect, useState } from 'react';
import { useSettings } from '../../SettingsContext';
import { createInquery, fetchCountries, fetchForm, formatDate } from '../../models/api';
import { calculatePrice, calculatePriceForItem, getPricesForDysplay } from '../../models/price';
import { emailPattern, getTotalMinutesFromTime, formatPriceString } from '../../models/utils';
import { Booking } from '../types/booking';
import { Country, FormField, Price } from '../types/settings';
import Input from '../utils/Input';
import './Quote.scss'
import { PieChart } from 'react-minimal-pie-chart';
import { Inquery, MainMeetingRoom, Timing, Upgrade } from '../types/inquery';
import { MeetingRoom } from '../types/meetingRooms';

type Props = {
    booking: Booking
    t: any
    onBookingCompleted: () => void
}

export default function Quote({ booking, onBookingCompleted, t }: Props) {
    const [settings] = useSettings();
    const priceSettings = settings?.price;
    const [user, setUser] = useState<any>({})
    const [error, setError] = useState<boolean>(false)
    const [sendingInquery, setSendingInquery] = useState<boolean>(false)
    const [required, setRequired] = useState<any>([])
    const [invalid, setInvalid] = useState<any>([])
    const [formFields, setForm] = useState<FormField[]>([])
    const [countries, setCountries] = useState<Country[]>([])
    const [prices, setPrices] = useState<any>({})

    const valid = true

    useEffect(() => {
        calculatePrices();
        getForm();
    }, [])

    async function calculatePrices() {
        const { totalPrice, pricePerGuest, totalPriceIncVAT, pricePerGuestIncVAT } = getPricesForDysplay(booking, undefined, undefined)

        const meetingRoomsCost = calculatePrice(booking, 'meetingRooms');
        const cateringsCost = calculatePrice(booking, 'caterings');
        const accomodationCost = calculatePrice(booking, 'accommodations');
        const activitiesCost = calculatePrice(booking, 'activities');
        const chartData = [
            { title: t('menu.meetingRooms'), value: meetingRoomsCost.price, color: '#eca45b' },
            { title: t('menu.caterings'), value: cateringsCost.price, color: '#C13C37' },
            { title: t('menu.accommodations'), value: accomodationCost.price, color: '#6A2135' },
            { title: t('menu.activities'), value: activitiesCost.price, color: '#2763e3' },
        ];
        setPrices({ totalPrice, pricePerGuest, totalPriceIncVAT, pricePerGuestIncVAT, chartData });
    }

    async function getForm() {
        const form = await fetchForm();
        const countries = await fetchCountries();
        setCountries(countries);
        setForm(form);
        // console.log('Booking', booking, form)
    }

    const handleChange = (event: any) => {
        const { target: { name, value } } = event;
        user[parseInt(name)] = value;
        setUser({ ...user });
    }
    const getInputType = (type: string): any => {
        switch (type) {
            case 'singleLineText':
                return 'text';
            case 'phoneNumber':
                return 'tel';
            case 'multiLineText':
                return 'textarea';
            case 'country':
            case 'title':
                return 'select';

            default: return type;
        }
    }

    function validate(user) {
        const required: number[] = []
        for (let field of formFields) {
            if (field.isMandatory && !user[field.id]) {
                required.push(field.id)
            }
        }
        const invalid: number[] = []
        setRequired(required)
        for (let key in user) {
            if (!user[key]) continue
            const inputField = formFields.find(field => field.id === parseInt(key))
            if (!inputField) continue
            if (inputField.formFieldType === 'email' && !emailPattern.test(user[key])) {
                invalid.push(inputField.id)
            }
        }
        setInvalid(invalid)
        return required.length === 0 && invalid.length === 0
    }

    function getUpgradeDetails(upgrade: any, parentInputValue: number | undefined, attendeesNo, type): Upgrade {
        if (!upgrade.inputValue) {
            upgrade.inputValue = parentInputValue
        }
        return {
            id: upgrade.id,
            adjustmentNo: upgrade.inputValue,
            price: calculatePriceForItem(booking, upgrade, attendeesNo, type).price
        }
    }

    function getMeetingRoomDetails(mr: MeetingRoom, attendeesNo): MainMeetingRoom | undefined {
        if (!mr) {
            return
        }

        return {
            id: mr.id,
            seatingLayoutId: mr.seatingLayouts.find(l => l.layout === mr.seatingLayout)?.id,
            attendeesNo: mr.attendeesNo,
            price: calculatePriceForItem(booking, mr, attendeesNo, 'meetingRooms').price,
            upgrades: mr.upgradeItems?.map(upgrade => getUpgradeDetails(upgrade, mr.inputValue, attendeesNo, 'meetingRooms'))
        }
    }

    function getDetails(mr: any, attendeesNo: number, type: string) {
        if (!mr) {
            return
        }

        return {
            id: mr.id,
            attendeesNo: null,
            adjustmentNo: mr.inputValue,
            price: calculatePriceForItem(booking, mr, attendeesNo, type).price,
            upgrades: mr.upgradeItems?.map(upgrade => getUpgradeDetails(upgrade, mr.inputValue, attendeesNo, type))
        }
    }

    const getQuote = async () => {
        if (!validate(user)) {
            return
        }

        const timings: Timing[] = [];
        for (let timing of booking.timings) {
            if (!timing || !timing.details) continue
            const { attendeesNo } = timing
            const { meetingRooms, caterings, accommodations, activities } = timing.details as any
            const mainMeetingRoom = getMeetingRoomDetails(meetingRooms?.find((mr: MeetingRoom) => mr.type === 'main'), attendeesNo)
            const additionalMeetingRooms = meetingRooms?.filter((mr: MeetingRoom) => mr.type === 'additional').map(mr => getMeetingRoomDetails(mr, attendeesNo))

            timings.push({
                date: timing.date,
                startTime: getTotalMinutesFromTime(timing.startTime),
                endTime: getTotalMinutesFromTime(timing.endTime),
                isPlusOneDay: !!timing.isPlusOneDay,
                attendeesNo: timing.attendeesNo,
                meetingTimeSlotId: timing.meetingTimeSlotId,
                mainMeetingRoom,
                additionalMeetingRooms,
                caterings: caterings.map(item => getDetails(item, attendeesNo, 'caterings')),
                accommodations: accommodations.filter(ac => !ac.earlyArrival).map(item => getDetails(item, attendeesNo, 'accommodations')),
                earlyArrivalAccommodations: accommodations.filter(ac => ac.earlyArrival).map(item => getDetails(item, attendeesNo, 'accommodations')),
                activities: activities.map(item => getDetails(item, attendeesNo, 'activities')),
            })
        }

        const clientData: any[] = []
        for (let key in user) {
            clientData.push({
                requestFormId: key,
                value: user[key]
            })
        }

        const inquery: Inquery = {
            inquiryDate: new Date().toISOString(),
            startDate: booking.startDate,
            durationDays: booking.durationDays,
            timings: timings,
            couponCode: booking.couponCode,
            clientData
        }

        setSendingInquery(true);
        const result = await createInquery(inquery);
        setSendingInquery(false);
        if (!result.error) {
            onBookingCompleted();
        } else {
            setError(true);
        }
        // console.log('Get quote', inquery, result)
    }
    
    const currency = priceSettings.currency.code;
    const netPrices = priceSettings.priceDisplay === 'net';

    const { totalPrice, pricePerGuest, totalPriceIncVAT, pricePerGuestIncVAT, chartData } = prices
    return (
        <div className="quote row">
            <div className="form col-lg-6">
                <h3>{t('quote.title')}</h3>
                <div>{t('quote.description')}</div>

                {formFields.map(item => {
                    return <Input
                        key={item.id}
                        fullWidth
                        type={getInputType(item.formFieldType)}
                        name={item.id.toString()}
                        description={item.description}
                        label={item.name + (item.isMandatory ? '*' : '')}
                        value={user[item.id]}
                        onChange={handleChange}
                        error={required.includes(item.id) && t('error.required') || invalid.includes(item.id) && t('error.invalid')}
                    >
                        {item.formFieldType === 'country' && [
                            <option key={0} value={undefined} ></option>,
                            (countries.map(country => <option key={country.id} value={country.twoLetterCode}>
                                {country.name}
                            </option>))]
                        }

                        {item.formFieldType === 'title' && [
                            <option key={0} value={undefined} >
                            </option>,
                            <option key={1} value={1}>
                                {t('title.mr')}
                            </option>,
                            <option key={2} value={2}>
                                {t('title.ms')}
                            </option>]}
                    </Input>
                })}
                <p>{t('form.footnote')}</p>

            </div>
            <div className="priceBreakdown col-lg-6">
                <h3>{t('quote.pricebreakdown')}</h3>
                <div className="prices">
                    <div className="mainPrice"> {currency} {formatPriceString(netPrices ? pricePerGuest : pricePerGuestIncVAT)} / guest</div>
                    <div className="total"> {currency} {formatPriceString(netPrices ? totalPrice : totalPriceIncVAT)} total</div>
                    <div className="vatPrice"> {currency} {formatPriceString(pricePerGuestIncVAT)} / guest inc VAT</div>
                    <div className="vatPrice"> {currency} {formatPriceString(totalPriceIncVAT)} total inc VAT</div>
                </div>
                <div className="chart">
                    <PieChart
                        lineWidth={30}
                        animate
                        labelPosition={75}
                        labelStyle={{ fontSize: '2.8px', fontWeight: 'bold', }}
                        label={(labelRenderProps) =>
                            labelRenderProps?.dataEntry.value > 0 ? labelRenderProps?.dataEntry.title : ''
                        }
                        data={chartData}
                    />
                </div>
            </div>
            <div className="col-sm-12 actions">
                {error && <div className="alert alert-danger">{t('inqueryFailed')}</div>}
                <button type="button" className="btn btn-lg btn-primary getQuote" disabled={sendingInquery} onClick={getQuote}>{t('form.getQuote')}</button>
            </div>
        </div>
    )
}
