import './Caterings.scss'
import CardItem from '../../card/CardItem';
import { Timing } from '../../types/booking';
import { Catering } from '../../types/caterings';
import React, { useEffect, useState } from 'react';
import { ItemType } from '../../../models/requiredItems';
import noAvailableItems from '../../utils/NoAvailableItems';

type Props = {
    cateringsAll: Catering[] | undefined
    caterings: Catering[],
    incompatibilities: number[],
    timeslot: Timing | undefined
    loading: boolean
    setCatering: (cateringTypes: Catering) => void,
    setUpgrades: (upgrade: Catering, cateringId: number) => void,
    updateItemInput: (value: number, item: ItemType, parentItem: ItemType, type: string) => void
    resetCaterings: (type: string) => void
    t: any
}
const cateringTypes = ['conferencePackage', 'individualCatering']
const buttomClassName = 'btn btn-link btn-block text-left'

export default function Caterings({ caterings, cateringsAll, incompatibilities, timeslot, loading, setCatering, setUpgrades, updateItemInput, resetCaterings, t }: Props) {
    const [filterType, setFilterType] = useState<string>()
    const [disabledItems, setDisabledItems] = useState<boolean>()
    const [expandedAccordion, setExpandedAccordion] = useState(getExpandedAccordion())

    useEffect(() => {
        if (caterings.length && filterType !== caterings[0].cateringType) {
            setFilterType(caterings[0].cateringType);
            setDisabledItems(caterings[0].cateringType === 'conferencePackage');
        } else {
            setDisabledItems(false);
        }
    }, [caterings])

    useEffect(() => {
        if (filterType) {
            resetCaterings(filterType);
        }
    }, [filterType])


    function getExpandedAccordion() {
        if (!filterType) {
            return 'cateringTypes';
        }
            
        return 'catering';
    }

    const onCateringSelected = (catering: Catering) => {
        setCatering(catering);
    }

    const onSetType = (type: string) => {
        let requiredCaterings = caterings.filter(c => c.cateringType !== type && c.required === true);
        if (requiredCaterings.length == 0) {
            setFilterType(type);
        }

        $('#catering').collapse('show');
    }

    if (!cateringsAll?.filter(u => u.sectionTypeOption === 'option')?.length && !loading) {
        return noAvailableItems(t);
    }

    return (
        <div>
            <div className="accordion" id="cateringsAccordion">
                <div className="card">
                    <div className="card-header" id="headingOne">
                        <h2 className="mb-0">
                            <button className={buttomClassName} type="button" data-toggle="collapse" data-target="#cateringTypes" aria-expanded="true" aria-controls="cateringTypes">
                                {t('cateringType')}
                            </button>
                        </h2>
                    </div>

                    <div id="cateringTypes" className={`collapse ${expandedAccordion === 'cateringTypes' ? 'show' : ''}`} aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="card-body row cateringTypes">
                            {cateringTypes.map(cateringType =>
                                <label className={`btn btn-secondary ${filterType === cateringType ? 'active' : ''}`} key={cateringType}>
                                    <input type="radio" name="options" id="option2" onClick={() => onSetType(cateringType)} disabled={disabledItems} /> {t(cateringType)}
                                </label>)}
                        </div>
                    </div>
                </div>

                <div className="card">
                    {filterType && <div className="card-header" id="headingTwo">
                        <h2 className="mb-0">
                            <button className={buttomClassName} type="button" data-toggle="collapse" data-target="#catering" aria-expanded="false" aria-controls="catering">
                                {t('chooseCatering')}
                            </button>
                        </h2>
                    </div>}
                    <div id="catering" className={`collapse  ${expandedAccordion === 'catering' || filterType ? 'show' : ''}`} aria-labelledby="headingTwo" data-parent="#accordionExample">
                        <div className="card-body row card-list">
                            {cateringsAll?.filter(item => cateringTypes.includes(item.cateringType) && (item.cateringType === filterType))
                                .map(catering => {
                                    const selected = caterings?.find(item => item.id === catering.id)
                                    return <CardItem
                                        key={catering.id}
                                        item={selected || catering}
                                        type="caterings"
                                        timeslot={timeslot}
                                        onSelect={onCateringSelected}
                                        updateItemInput={updateItemInput}
                                        checked={!!selected} 
                                        t={t}
                                        disabled={(!selected && !filterType && disabledItems) || incompatibilities.includes(catering.id)} />
                                })}
                        </div>
                    </div>
                </div>
                {caterings?.map(catering => {
                    const allUpgrades = cateringsAll?.filter(item => item.sectionTypeOption === 'upgrade' && catering.cateringType === filterType && catering.upgrades?.includes(item.id))
                    if (!allUpgrades?.length) return

                    return (<div className="card" key={catering.id}>
                        <div className="card-header" id="headingThree">
                            <h2 className="mb-0">
                                <button className={buttomClassName} type="button" data-toggle="collapse" data-target="#upgrades" aria-expanded="false" aria-controls="upgrades">
                                    {t('upgrades', { name: catering.name })}
                                </button>
                            </h2>
                        </div>
                        <div id="upgrades" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div className="card-body row card-list">
                                {allUpgrades?.map(upgrade => {
                                    const selected = catering.upgradeItems?.find(u => u.id === upgrade.id)
                                    return <CardItem
                                        key={upgrade.id}
                                        item={selected || upgrade}
                                        parentItem={catering}
                                        timeslot={timeslot}
                                        type="caterings"
                                        onSelect={() => setUpgrades(upgrade, catering.id)}
                                        updateItemInput={updateItemInput}
                                        checked={selected?.id === upgrade.id} 
                                        t={t}
                                        disabled={incompatibilities.includes(upgrade.id)} />
                                })}
                            </div>
                        </div>
                    </div>)
                })}
            </div>
        </div>
    )
}
