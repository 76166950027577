import "./App.scss";
import Loader from "react-loader-spinner";
import { useSettings } from "../SettingsContext";
import Quote from "./quote/Quote";
import Timeline from "./header/Timeline";
import { Booking } from "./types/booking";
import { detailsMock, mockDetailsFullStep, firstStep } from "./utils/mockData";
import FirstStep from "./firstStep/FirstStep";
import { fetchSettings } from "../models/api";
import { applyTheming } from "../models/theming";
import { withNamespaces } from "react-i18next";
import { Details } from "../models/requiredItems";
import React, { useEffect, useRef, useState } from "react";
import { DetailsStep } from "./detailsStep/DetailsStep";
import InquerySuccess from "./inquerySuccessStep/InquerySuccess";
import PageNotFound from "./utils/PageNotFound";
import NothingSelected from "./detailsStep/modal/NothingSelected";
import InqueryAcceptedInfo from "./inquiryAcceptedInfo/InqueryAcceptedInfo";

function App({ t }: any) {
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [quoteStepIndex, setQuoteStepIndex] = useState(7);
  const [previousStep, setPreviousStep] = useState(0);
  const [booking, setBooking] = useState<Booking>();
  const [showNothingSelectedModal, setShowNothingSelectedModal] =
    useState(false);
  const [settings, setSettings] = useSettings();
  const [acceptInqiryRefId, setAcceptInqiryRefId] = useState<string>();
  const [acceptInqiryVersionId, setAcceptInqiryVersionId] = useState<string>();
  const isAcceptingInquiryAction =
    window.location.pathname.includes("inquiry/accept") &&
    window.location.search.includes("refId=") &&
    window.location.search.includes("v=");

  // redirect to compnent for accepted inquiry
  if (isAcceptingInquiryAction) {
    var urlParams = new URLSearchParams(window.location.search);
    const v = urlParams.get("v");
    const refId = urlParams.get("refId");
    if (v && refId) {
      if (loading) {
        setLoading(false);
        setStep(-100);
        setAcceptInqiryVersionId(v);
        setAcceptInqiryRefId(refId);
      }
    }
  }

  useEffect(() => {
    getSettings();
  }, []);

  const getBookingDurationDays = () =>
    !!booking && !!booking.durationDays ? booking.durationDays : 0;

  useEffect(() => {
    if (!isAcceptingInquiryAction) {
      let numberOfBookingDays = getBookingDurationDays();
      // indexStep 1 - first step
      // indexStep 1 + Number of days - details per each day of the booking
      // indexStep 1 + indexStep 1 + Number of days + 1 - index of the Quote for the inquiry
      // indexStep 1 + indexStep 1 + Number of days + 1 + 1 - index of the InquirySucess (last page)
      setQuoteStepIndex(Math.max(1 + numberOfBookingDays + 1, 3));
    }
  }, [getBookingDurationDays()]);

  async function getSettings() {
    const settings = await fetchSettings();
    setLoading(false);
    if (!settings) {
      return;
    }

    setSettings(settings);

    if (settings?.design) {
      applyTheming(settings.design);
    }
  }

  const bookingHasAtLeastOneEmptyDay = () => {
    if (!booking || !booking.timings || booking.timings.length === 0) {
      return true;
    }
	
	if (booking.timings.some((timing) => timing.details === undefined || timing.details === null)) {
      return true;
    }

    return booking.timings.some(
      (timing) =>
        !!timing.details &&
        timing.details.meetingRooms.length === 0 &&
        timing.details.caterings.length === 0 &&
        timing.details.accommodations.length === 0 &&
        timing.details.activities.length === 0
    );
  };

  const onStepSelect = (changeToStep: number) => {
    if (changeToStep === step) return;

    setPreviousStep(step);

    const firstStep = firstStepRef?.current as any;
    let canGoNext = true;
    if (firstStep) canGoNext = firstStep?.onNextRequested();

    if (!canGoNext) {
      return;
    }

    const detailsStep = detailsRef?.current as any;
    if (detailsStep) {
      let bookingDetailsForDay = detailsStep?.onNextRequested();
      if (!!bookingDetailsForDay) {
        setBookingDetails(
          bookingDetailsForDay.details,
          bookingDetailsForDay.date
        );
      }
    }

    // if going to Inquiry
    if (
      (!!booking && booking.durationDays + 1 < changeToStep) ||
      changeToStep === quoteStepIndex
    ) {
      if (!bookingHasAtLeastOneEmptyDay()) {
        setStep(quoteStepIndex);
      } else {
        setShowNothingSelectedModal(true);
      }
      return;
    }

    setStep(changeToStep);
  };

  const onBookingCompleted = () => setStep(quoteStepIndex + 1);

  const getUrlFromInquiryAccpetStartOver = () => {
    var urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get("userId");
    const lang = urlParams.get("lang");
    var urlResult = window.location.origin;
    if (!!userId) {
      urlResult = `${urlResult}?userId=${userId}`;
    }
    if (!!lang) {
      urlResult = `${urlResult}&lang=${lang}`;
    }
    return urlResult;
  };

  const startOver = () => {
    setBooking(undefined as any);
    setStep(1);
    if (isAcceptingInquiryAction) {
      window.location.href = getUrlFromInquiryAccpetStartOver();
    } else {
      window.location.reload();
    }
  };

  const onBookingStarted = (booking: Booking) => {
    setBooking(booking);
    setStep(Math.min(step + 1, 3));
  };

  const setBookingDetails = (details: Details, date: string) => {
    if (!booking) return;
    for (const timing of booking.timings) {
      if (timing.date === date) {
        timing.details = details;
      }
    }
    setBooking({ ...booking, timings: [...booking.timings] });
  };

  const firstStepRef = useRef();
  const detailsRef = useRef();

  function renderStep() {
    const day = step - 1;

    if (loading) return;

    if (step === -100) {
      return (
        <InqueryAcceptedInfo
          v={acceptInqiryVersionId}
          refId={acceptInqiryRefId}
          startOver={startOver}
          t={t}
        />
      );
    }

    if (!settings || step < 1 || step > quoteStepIndex + 1) {
      return <PageNotFound t={t} />;
    }

    if (step === 1) {
      return (
        <FirstStep
          ref={firstStepRef}
          booking={booking}
          onNext={onBookingStarted}
          t={t}
        />
      );
    }

    if (step > 1 && step < quoteStepIndex) {
      return (
        <DetailsStep
          ref={detailsRef}
          key={day}
          day={day}
          booking={booking}
          onStepSelect={onStepSelect}
          previousStep={previousStep}
          t={t}
        />
      );
    }

    if (step === quoteStepIndex) {
      return (
        booking && (
          <Quote
            booking={booking}
            onBookingCompleted={onBookingCompleted}
            t={t}
          />
        )
      );
    }

    if (step === quoteStepIndex + 1) {
      return <InquerySuccess startOver={startOver} t={t} />;
    }
  }

  return (
    <div className="App container">
      {!loading && settings && !isAcceptingInquiryAction && (
        <header className="App-header">
          <Timeline
            step={step}
            duration={booking?.durationDays}
            onStepSelect={onStepSelect}
            date={booking?.startDate}
            quoteStepEnabled={!bookingHasAtLeastOneEmptyDay()}
            quoteStepIndex={quoteStepIndex}
            t={t}
          />
        </header>
      )}

      {loading && (
        <div className="card-body row loading">
          <Loader type="ThreeDots" color="#000000" height={40} width={40} />
        </div>
      )}
      {showNothingSelectedModal && (
        <NothingSelected
          setShowNothingSelectedModal={setShowNothingSelectedModal}
          t={t}
        />
      )}
      {!loading && renderStep()}
    </div>
  );
}

export default withNamespaces()(App);
